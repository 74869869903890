import React from 'react';
import back from '../../Components/assets/home/backdrop.jpg'
import img1 from '../../Components/assets/home/corpo.png'
import img2 from '../../Components/assets/home/course.png'
import img3 from '../../Components/assets/home/SCHOLARSHIP.png'

import steps from '../../Assets/the scholarship journey (1).png'


const serviceData = [
    {
        icon: img1,
        title: '85% Application Acceptance Rate',
        description:
            'At GetScholify, we provide the students a wide variety of scholarships offered by many reputed college nationwide, giving students, a wide variety of options to choose from.',
    },
    {
        icon: img2,
        title: 'Many Scholarships at Single Platform',
        description:
            'Choose from a variety of scholarships of different eligibility and criteria offered by government and colleges themselves. Register at GetScholify to know more about them',
    },
    {
        icon: img3,
        title: 'Upto 100% Scholarship Available',
        description:
            'Get a chance to study at the college of your choice and the course program of your liking with GetScholify. Our procedures are transparent, authentic, and trustworthy and also trackable',
    },
];

const Vision = () => {
    return (
        <section className="py-4 bg-[#f8f8f8]">
            <div className="w-10/12 mx-auto  py-4">
                <p className="font-semibold text-2xl md:text-4xl border-l-4 border-[#b02027] px-4 mb-4">
                    How It Works
                </p>
                <div className="flex flex-wrap md:flex-nowrap gap-4 g-3">
                    <div className="w-full md:w-1/3">
                        <div className="bg-[#b02027] text-white p-4 rounded">
                            <div className="d-flex gap-3 flex-fill">
                                <p className="pb-4 font-bold text-xl">
                                    We Are :
                                </p>
                            </div>
                            <div className="flex-fill flex flex-col justify-between font-semibold">
                                <p className="text-2xl md:text-3xl pb-4 mb-0">
                                    Trusted
                                </p>
                                <p className="text-2xl md:text-3xl pb-4 mb-0">
                                    Secured
                                </p>
                                <p className="text-2xl md:text-3xl pb-4 mb-0">
                                    Accesscible
                                </p>
                                <p className="text-2xl md:text-3xl pb-4 mb-0">
                                    Transparent
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-1/3">
                        <div className="card how-it-works-card border-0 shadow-sm bg-white h-full">
                            <div className="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                                <img src="https://images.unsplash.com/flagged/photo-1574097656146-0b43b7660cb6?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" className="img-fluid my-images h-[30vh] object-cover" />
                                <a href="#!">
                                    <div className="mask" style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }} />
                                </a>
                            </div>
                            <div className="card-body p-5">
                                <h3 className="text-xl md:text-2xl text-[#b02027] font-semibold pb-3">For Students:</h3>
                                <ol className="">
                                    <li>Register as a student.</li>
                                    <li>Access various scholarships.</li>
                                    <li>Secure academic or sports merit scholarships.</li>
                                    <li>Contribute as an individual.</li>
                                    <li>Benefit from accessible and transparent scholarship opportunities.</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-1/3 ">
                        <div className="card how-it-works-card border-0 shadow-sm bg-white h-full">
                            <div className="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                                <img src="https://www.getscholify.com/assets/images/business.66a02ab22edb24ee9212.jpg" className="object-cover w-full h-[30vh] " />
                                <a href="#!">
                                    <div className="mask" style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }} />
                                </a>
                            </div>
                            <div className="card-body p-5">
                                <h3 className="text-xl md:text-2xl text-[#b02027] font-semibold pb-3">For Corporates and Colleges:</h3>
                                <ol className="">
                                    <li>Register as a corporate or college.</li>
                                    <li>Contribute towards scholarships.</li>
                                    <li>Find meritorious students.</li>
                                    <li>Explore specialized scholarships like Army Personnel Scholarship and Single Girl
                                        Child Scholarship.</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap md:flex-nowrap gap-4 py-4">
                    <img src={steps} alt="" srcset="" className="w-full" />
                </div>
            </div>
        </section>

    );
};

export default Vision;
