import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import icon1 from '../../Assets/Icons/agri.png';
import icon2 from '../../Assets/Icons/business.png';
import icon3 from '../../Assets/Icons/ca.png';
import icon4 from '../../Assets/Icons/comms.png';
import icon5 from '../../Assets/Icons/Dental.png';
import icon6 from '../../Assets/Icons/education.png';
import icon7 from '../../Assets/Icons/Engineering.png';
import icon8 from '../../Assets/Icons/hotel.png';
import icon9 from '../../Assets/Icons/Law.png';
import icon10 from '../../Assets/Icons/Nursing.png';
import icon11 from '../../Assets/Icons/Para-Medical.png';
import icon12 from '../../Assets/Icons/Pharmacy.png';
import namesData from '../../namesData';
import BasicQueryForm from '../GlobalComponents/BasicQueryForm';
const Courses = () => {
    const [selectedCategory, setSelectedCategory] = useState(namesData[0].category);
    const [showFormModal, setShowFormModal] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState('');
    const handleFormModalToggle = (courseName) => {
        setShowFormModal(!showFormModal);
        setSelectedCourse(courseName)
    };
    return (
        <div className="w-10/12 mx-auto py-12 ">
            <div className="w-full flex flex-wrap rounded-md shadow-md border">
                <div className="w-full md:w-1/3 p-8 bg-[#b02027] text-white">
                    <p className="text-xl md:text-3xl pb-8 font-semibold">Select Course Category</p>
                    <select
                        className="block md:hidden text-lg bg-inherit py-4 cursor-pointer"
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                    >
                        {namesData.map((name) => (
                            <option key={name.id} value={name.category}>
                                {name.category}
                            </option>
                        ))}
                    </select>
                    {namesData.map(name => (
                        <p
                            key={name.id}
                            className={`hidden md:block text-lg border-b border-[#ffffff50] py-4 cursor-pointer ${selectedCategory === name.category ? 'text-[#ffffff]' : ''}`}
                            onClick={() => setSelectedCategory(name.category)}
                        >
                            {name.category}
                        </p>
                    ))}
                </div>
                <div className="w-full md:w-2/3 p-8">
                    <p className="text-lg md:text-3xl font-semibold text-[#b02027] pb-5">
                        Courses
                    </p>
                    {namesData.map(name => (
                        name.category === selectedCategory &&
                        name.names.map(category => (
                            <div key={category.category}>
                                <p className="text-xl font-semibold py-4 text-[#b02027]">{category.category}</p>
                                {category.names.map(course => (
                                    <div key={course.name} className="border-b border-gray-300 py-4">
                                        <div className='flex justify-between items-center'>
                                            <div>
                                                <p className="text-md font-semibold pb-3">{course.name}</p>
                                                <p className="text-gray-600">{course.description}</p>
                                            </div>

                                            <button onClick={()=> {handleFormModalToggle(course.name)}}><p className='text-[#b02027] font-semibold bg-gray-100 rounded-md px-2 py-1 w-fit'> <i className='fa fa-arrow-right'></i></p></button>
                                            {showFormModal && (
                                                <div className="z-50 fixed inset-0 w-full  flex justify-center items-center bg-black bg-opacity-10">
                                                    <div className="bg-white p-8 w-10/12 mx-auto h-[80vh] overflow-auto rounded-md flex flex-col justify-center">
                                                        <div className='flex justify-end'>
                                                            <button className=" text-white bg-[#b02027] text-lg px-3 rounded-md mb-4" onClick={handleFormModalToggle}>
                                                                <i className="fas fa-times"></i>
                                                            </button>
                                                        </div>
                                                        {
                                                            <BasicQueryForm cat={selectedCourse} />
                                                        }
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                    </div>
                                ))}
                            </div>
                        ))
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Courses;
