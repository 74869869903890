const namesData = [
    {
        id: 1,
        category: "ENGINEERING",
        names: [
            {

                category: "M.Tech Programs",
                names: [
                    {
                        name: "M.TECH CIVIL ENGINEERING",
                        description: "Deals with designing, supervision, and building of roads, bridges, tunnels, buildings, airports, dams, sewage systems, ports, etc."
                    },
                    {
                        name: "M.TECH ELECTRICAL ENGINEERING",
                        description: "Advanced study of electrical and electronics engineering, computers, communication, electricity, transmission, and generation."
                    },
                    {
                        name: "M.TECH ELECTRONICS AND COMMUNICATION ENGINEERING(PART TIME)",
                        description: "Upgrades skill of working engineers with new technologies for improved quality and profitability."
                    },
                    {
                        name: "M.TECH CIVIL (CONSTRUCTION TECHNOLOGY & MANAGEMENT)",
                        description: "Focuses on construction technology with emphasis on management."
                    },
                    {
                        name: "M.TECH CIVIL (GEOTECHNOLOGY ENGINEERING)",
                        description: "Application of principles of soil mechanics and rock mechanics for investigation of subsurface materials."
                    },
                    {
                        name: "M.TECH MECHANICAL ENGINEERING",
                        description: "Study of mechanics, kinematics, etc., used for construction purpose and installation of tools and machines."
                    }
                ]
            },
            {
                category: "B.Tech Programs",
                names: [
                    {
                        name: "B.TECH - MECHANICAL ENGINEERING",
                        description: "Prepares students for designing, manufacturing, and maintenance of mechanical systems."
                    },
                    {
                        name: "B.TECH - COMPUTER SCIENCE AND ENGINEERING",
                        description: "Studies practical and theoretical knowledge of computer hardware, software, nameming, and networking."
                    },

                ]
            },
           
        ]
    },

    {
        id: 2,
        category: "PHARMACY",
        names: [
            {
                category: "Pharmacy",
                names: [
                    {
                        name: "BACHELOR IN PHARMACY",
                        description: "Bachelor of Pharmacy (B.Pharma) is a four-year undergraduate name in which students study the methods and process of preparing drugs and how does the dispensing of medicine and drugs take place. Anyone who aspires to be a pharmacist today, it is mandatory for them to undertake this course so that they have thorough and in-depth knowledge of the science of the medicine."
                    },
                    {
                        name: "MASTER IN PHARMACY",
                        description: "Pharmacist, Chemist or Druggist different term with similar speciality i.e. preparing medicines, helping with a healing procedure of patients, advising them as well as the doctors/medical practitioners about medicines use, dosages, side effects and even the diet required while consuming the medicines."
                    }
                ]


            }
        ]
    },

    // Pharmacy
    {
        id: 3,
        category: "PARA-MEDICAL",
        names: [
            {
                category: "Paramedical",
                names: [

                    {
                        name: "B.SC-RADIO MEDICAL IMAGING TECHNOLOGY",
                        description: "B.Sc. in Medical Radiography & Imaging Technology is a 3-year undergraduate course in Medical Lab Technology. Medicinal Radiography and Imaging Technology involves the methods and processes used to take internal pictures of the human body parts/ organs, and capacities thereof, for clinical purposes, such as investigation of the body’s normal anatomy and physiology."
                    },
                    {
                        name: "B.SC-OPERATION THEATER TECHNOLOGY",
                        description: "The duration of B.Sc Operation Theatre Technology is 3 years. This is a full-time degree course and to pursue this course every student must complete their 12th final year examination or any equivalent examination in Science stream with Physics, Biology, and Chemistry as compulsory subjects from any recognized board."
                    },
                    {
                        name: "B.SC-OPTOMETRY",
                        description: "Bachelor of Science degree in Optometry field provides extensive training and medical knowledge about the human eye. Apart from making the fundamentals of the human eye clear, this course will also teach how to operate various types of medical equipment that are a must for every optometry student and practitioner to know."
                    },
                    {
                        name: "B.SC-RADIOLOGY & IMAGING TECHNOLOGY",
                        description: "B.Sc. in Radiology & Imaging Technology is a 3-year undergraduate course in Medical Lab Technology. Radiology and Imaging Technology involves the methods and processes used to take internal pictures of the human body parts/ organs, and capacities thereof, for clinical purposes, such as investigation of the body’s normal anatomy and physiology."
                    },
                    {
                        name: "B.SC-MEDICAL LAB SCIENCE",
                        description: "B.Sc. Medical Laboratory Sciences (MLS) is a healthcare professional course concerned with the diagnosis, treatment and prevention of diseases through the use of chemical, hematological, immunologic, microscopic, and bacteriological diagnostic analyses."
                    },
                    {
                        name: "M.SC-MLS-CLINICAL RESEARCH",
                        description: "Masters in Medical Lab Science is a master’s degree level course in Lab science. This name allows a student to level up their skills and gain advance skills in medical lab science to elevate their career and become a leader in medical laboratory profession as clinical scientist."
                    },
                    {
                        name: "M.SC-ANESTHESIA & OPERATION THEATER",
                        description: "M.Sc Anaesthesia and Operation Theatre technology is a course designed to provide in depth knowledge & impart skills for working as an assistant to the consultant Anaesthesiologist as well as to assist in preparation of operation theatre before & during an elective or emergency surgery."
                    }
                ]


            }

        ]
    },

    // Business Management

    {
        id: 4,
        category: "Business Management",
        names: [
            {
                category: "Business Managemnt",
                names: [
                    {
                        name: "BACHELOR IN BUSINESS ADMINISTRATION (BBA)",
                        description: "BBA is an undergraduate course that helps develop entrepreneurship skills of the candidates. It combines business-related courses and generic courses."
                    },
                    {
                        name: "MASTER IN BUSINESS ADMINISTRATION(MBA)",
                        description: "A Master of Business Administration (M.B.A. degree) is a general Management degree designed to educate business students in areas such as Finances, Human Resources, Marketing, Strategy, and Operations Management."
                    }
                ]
            }
        ]
    },

    // Computer Application 
    {
        id: 5,
        category: "Computer Application",
        names: [
            {
                category: "Computer Application",
                names: [
                    {
                        name: "BACHOLER OF COMPUTER APPLICATION(BCA)",
                        description: "BCA is a three-year undergraduate degree program for candidates wishing to delve into the world of Computer languages. It is one of the most popular options to get started with a career in Information Technology, providing insights into computers and their applications."
                    },
                    {
                        name: "MASTER OF COMPUTER APPLICATION(MCA)",
                        description: "Master of Computer Applications (MCA) is a two-year professional post-graduate program for candidates wanting to delve deeper into the world of computer application development. The program offers a blend of both theoretical and practical knowledge, focusing on learning modern programming languages."
                    }
                ]
            }
        ]
    },

    // Agriculture 
    {
        id: 6,
        category: "Agriculture",
        names: [
            {
                category: " Agriculture ",
                names: [
                    {
                        name: "B.SC AGRICULTURE(HONS)",
                        description: "B.Sc. (Hons) Agriculture or Bachelor of Science Honours in Agriculture is an undergraduate Agriculture Science and Technology course. Agriculture is a science or practice of farming, including cultivation of the soil for the growing of crops and the rearing of animals to provide."
                    },
                    {
                        name: "M.SC AGRICULTURE(HONS)",
                        description: "The industry of agriculture is a good field to make your career. The profession has both fame and money. Apart from that, the subjects related to the courses are extremely appealing to study with."
                    }
                ]
            },
        ]
    },

    // Law
    {
        id: 7,
        category: "Law",
        names: [
            {
                category: "LLB (Bachelor of Laws)",
                names: [
                    {
                        name: "LLB (BACHELOR OF LAWS)",
                        description: "LLB is a three-year Bachelor of Law degree that is offered to aspirants by many renowned colleges in India. However, candidates can pursue this law course only if they possess a graduation degree. The three-year LLB course."
                    },
                    {
                        name: "BA. LLB (Bachelor of Arts - Bachelor of Legislative Law)",
                        description: "BA. LLB (Bachelor of Arts - Bachelor of Legislative Law) is a five-year integrated LLB course that candidates are eligible to pursue after completing Class 12. The Law course is an amalgamation of Humanities and Law streams, which allows candidates to study subjects such as History, Sociology, Administrative Law, Criminology, Family Law and so on."
                    },
                    {
                        name: "BBA. LLB (Bachelor of Business Administration & Bachelor of Legislative Law)",
                        description: "Bachelor of Business Administration & Bachelor of Legislative Law (BA LLB) is a five-year course on completion of which the aspirants are offered a professional degree. BBA LLB is an integrated law course wherein aspirants are taught subjects related to Business Administration as well as Law."
                    }
                ]
            }
        ]
    },

    // Hotel & Tourism

    {
        id: 8,
        category: "Hotel Management",
        names: [
            {
                category: "Hotel Management Courses",
                names: [
                    {
                        name: "B.SC. (HOTEL MANAGEMENT)",
                        description: "Candidates who want to make a future in the hospitality industry can consider pursuing Bachelor of Hotel Management and Catering Technology (BHMCT) course. Offered at the undergraduate level, BHMCT course is of four years duration. In majority of the colleges BHMCT course is divided into eight semesters."
                    },
                    {
                        name: "BACHOLER OF VOCATIONAL HOTEL MANAGEMENT DIETICS AND NUTRITION",
                        description: "B.Sc. Hospitality Management and Dietetics or Bachelor of Science in Hospitality Management and Dietetics is an undergraduate Hospital and Health Care Management course. The program provides a broad education in the science of nutrition and preparation of food as related to lifestyles, cultures, and health."
                    },
                    {
                        name: "MASTER OF HOTEL MANAGEMENT & CATERING TECHNOLOGY",
                        description: "The Master of Hotel Management & Catering Technology (MHMCT), is a two years postgraduate Hospitality Management Degree course in India. It’s a service-oriented course, which develop the human service resource to fulfil the requirements of Hospitality Industry."
                    }
                ]
            }
        ]
    },


    // Education 
    {
        id: 9,
        category: "Education",
        names: [
            {
                category: "Education Degrees",
                names: [
                    {
                        name: "B.ED",
                        description: "B.Ed is the bachelor’s degree undertaken to take up teaching as a profession in schools. However, it is worth knowing that B.Ed or Bachelor of Education is not an undergraduate degree and to pursue this course one needs to have completed his/her graduation. Thus, B.Ed is a professional course and right after completing this course, students can get a job at school level."
                    },
                    {
                        name: "M.ED",
                        description: "MEd or Master of Education is a master degree course that deals with the study of new methods of teaching and educational research. The programme focuses on different aspects of education including instruction, curriculum, counselling, leadership, and educational technology. The regulatory body in charge of the MEd programme is the National Council of Higher Education (NCHE)."
                    }
                ]
            }
        ]
    },

    // Dental
    {
        id: 10,
        category: "Dental",
        names: [
            {
                category: "Dental Courses",
                names: [
                    {
                        name: "BDS",
                        description: "BDS (Bachelor of Dental Surgery) is a 5-years undergraduate program and the only approved professional dental course. It is a compulsory course for the students aspiring to work as dentists at government or private hospitals. BDS is one of the most popular medical courses after MBBS."
                    }
                ]
            }
        ]
    },

    // Media

    {
        id: 11,
        category: "Media and Communication",
        names: [
            {
                category: "Bachelor's Degrees",
                names: [
                    {
                        name: "BACHELOR OF JOURNALISM AND MASS COMMUNICATION",
                        description: "Bachelor of Journalism & Mass Communication (BJMC) is an undergraduate program that provides aspirants with knowledge about various journalistic skills in mass media such as newspapers, television, radio, internet etc. It also informs students about various technology, equipment and software involved in mass media."
                    },
                    {
                        name: "BACHELOR OF ARTS IN DIGITAL MEDIA AND MARKETING",
                        description: "Today’s job market is shifting, and jobs that once required a basic education now demand greater credentials. A bachelor’s degree is becoming a common requirement for some entry-level positions. For those looking to set themselves up for a bright future, a Bachelor of Arts (BA) could be a great step."
                    }
                ]
            }
        ]
    },

    // nursing
    {
        id: 12,
        category: "Nursing",
        names: [
            {
                category: "Nursing Degrees",
                names: [
                    {
                        name: "B.SC. NURSING",
                        description: "Graduation in Nursing or B. Sc. (Bachelor of Science) in Nursing is a four-year professional course. To pursue B. Sc. nursing the candidates should have minimum eligibility criteria, having completed 10+2 with Physics, Chemistry, and Biology as mandatory subjects."
                    }
                ]
            }
        ]
    },

];

export default namesData;
