import { GoogleAuthProvider, AppleAuthProvider, PhoneAuthProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, signInWithPhoneNumber, sendPasswordResetEmail, updatePassword, sendEmailVerification } from "firebase/auth";
import { auth } from "./firebase";

export const doCreateUserWithEmailAndPassword = async (email, password) => {
    try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        return userCredential.user;
    } catch (error) {
        throw error;
    }
}

export const doSignInWithEmailAndPassword = async (email, password) => {
    try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        return userCredential.user;
    } catch (error) {
        throw error;
    }
}

export const doSignInWithGoogle = async () => {
    try {
        const provider = new GoogleAuthProvider();
        const result = await signInWithPopup(auth, provider);
        return result.user;
    } catch (error) {
        throw error;
    }
}



export const doSignInWithPhoneNumber = async (phoneNumber) => {
    try {
        const provider = new PhoneAuthProvider();
        const verificationId = await signInWithPhoneNumber(auth, phoneNumber, provider);
        return verificationId;
    } catch (error) {
        throw error;
    }
}
export const doSignOut = () => {
    return auth.signOut()
}
export const doPasswordReset = (email) => {
    return sendPasswordResetEmail(auth, email)
}
export const doPasswordChange = (password) => {
    return updatePassword(auth.currentUser, password)
}
export const doSendEmailVerification = () => {
    return sendEmailVerification(auth.currentUser, {
        url: `${window.location.origin}/`
    })
}
