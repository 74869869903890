import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard, faListAlt, faUsers, faChartLine } from '@fortawesome/free-solid-svg-icons';
import CreateScholarship from './CreateScholarship';
import UserList from './UserList';
import StudentScholarshipStatus from './StudentScholarshipStatus';
import ScholarshipRequests from './ScholarshipRequests';

const AdminDashboard = () => {
    const [activeTab, setActiveTab] = useState('form');

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className="flex flex-col md:flex-row h-screen">
            {/* Side Navigation for Desktop */}
            <div className="bg-gray-800 md:w-1/5 text-white hidden md:block">
                <div className="p-4">
                    <h1 className="text-3xl mb-10">Dashboard</h1>
                    <ul>
                        <li
                            className={`mb-4 border-b py-2 cursor-pointer transition duration-300 flex items-center ${activeTab === 'form'
                                ? 'text-white font-semibold border-white bg-gray-700'
                                : 'text-gray-400 hover:text-white hover:border-white'
                                }`}
                            onClick={() => handleTabChange('form')}
                        >
                            <FontAwesomeIcon icon={faClipboard} className="mr-3" /> Create A Scholarship
                        </li>
                        <li
                            className={`mb-4 border-b py-2 cursor-pointer transition duration-300 flex items-center ${activeTab === 'result'
                                ? 'text-white font-semibold border-white bg-gray-700'
                                : 'text-gray-400 hover:text-white hover:border-white'
                                }`}
                            onClick={() => handleTabChange('result')}
                        >
                            <FontAwesomeIcon icon={faListAlt} className="mr-3" /> Scholarship Requests
                        </li>
                        <li
                            className={`mb-4 border-b py-2 cursor-pointer transition duration-300 flex items-center ${activeTab === 'progress'
                                ? 'text-white font-semibold border-white bg-gray-700'
                                : 'text-gray-400 hover:text-white hover:border-white'
                                }`}
                            onClick={() => handleTabChange('progress')}
                        >
                            <FontAwesomeIcon icon={faChartLine} className="mr-3" /> Scholarship List
                        </li>
                        <li
                            className={`mb-4 border-b py-2 cursor-pointer transition duration-300 flex items-center ${activeTab === 'users'
                                ? 'text-white font-semibold border-white bg-gray-700'
                                : 'text-gray-400 hover:text-white hover:border-white'
                                }`}
                            onClick={() => handleTabChange('users')}
                        >
                            <FontAwesomeIcon icon={faUsers} className="mr-3" /> User List
                        </li>
                    </ul>
                </div>
            </div>

            {/* Side Navigation for Mobile and Tablet */}
            <div className="md:hidden w-full bg-gray-800 text-white p-4">
                <h1 className="text-xl font-bold mb-4">Dashboard</h1>
                <select
                    className="block w-full p-2 bg-gray-900 text-white rounded"
                    value={activeTab}
                    onChange={(e) => handleTabChange(e.target.value)}
                >
                    <option value="form">Scholarship Form</option>
                    <option value="progress">Progress Dashboard</option>
                    <option value="result">Scholarship Result</option>
                    <option value="users">User List</option>
                </select>
            </div>

            {/* Main Content */}
            <div className="w-full md:h-full p-4 bg-gray-100">
                {activeTab === 'form' && <CreateScholarship />}
                {activeTab === 'progress' && <StudentScholarshipStatus />}
                {activeTab === 'result' && <ScholarshipRequests />}
                {activeTab === 'users' && <UserList />}
            </div>
        </div>
    );
};

export default AdminDashboard;
