import React from 'react'
import CorpHeader from '../Components/Corporate/CorpHeader'
import CorpForm from '../Components/Corporate/CorpForm'

const Corporate = () => {
    return (
        <div>
            <CorpHeader />
            <CorpForm />
        </div>
    )
}

export default Corporate