import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
const BasicQueryForm = ({ cat }) => {
    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        username: '',
        phoneNumber: '',
        email: '',
        preference: cat
    });
    const [submitting, setSubmitting] = useState(false); // State for submission status

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitting(true); // Set submitting to true while submitting

        try {
            const response = await fetch('https://getscholify-backend.onrender.com/api/submit-form', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            if (response.ok) {
                alert('Form Submitted Successfully');
                setFormData({
                    username: '',
                    phoneNumber: '',
                    email: '',
                    preference: cat
                });
              
            } else {
                alert('Error: ' + response.statusText); // Concatenate the error message
                console.error('Error:', response.statusText);
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setSubmitting(false); // Reset submitting status regardless of success or failure
        }
    };

    return (
        <div className='w-full'>
            <div className="w-10/12 mx-auto z-50">
                <p className="text-xl font-bold pb-4">Basic Query Form</p>
                <form className="bg-white w-full shadow-md rounded px-8 pt-6 pb-8 mb-4" onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                            Your Name
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="username"
                            type="text"
                            name="username"
                            placeholder="Username"
                            value={formData.username}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-6">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phoneNumber">
                            Your Phone Number
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                            id="phoneNumber"
                            type='number'
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-6">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                            Your Email
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                            id="email"
                            type='email'
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-6">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="preference">
                            Your Preference
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                            id="preference"
                            type='text'
                            name="preference"
                            value={formData.preference}
                            disabled
                        />
                    </div>
                    <div className="flex items-center justify-between">
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="submit"
                            disabled={submitting} // Disable button while submitting
                        >
                            {submitting ? 'Submitting...' : 'Submit'} {/* Change button text while submitting */}
                        </button>
                       
                    </div>
                </form>
                <p className="text-center text-gray-500 text-xs">
                    Fill This Form To Process To Login
                </p>
            </div>
        </div>
    );
};

export default BasicQueryForm;
