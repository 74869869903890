import React from 'react';
import mt from '../../Assets/Images/methodology.png'

const Methodology = () => {
    return (
        <section className="py-3 py-md-5 py-xl-8 bg-light">
            <div className="w-10/12 mx-auto py-4  ">
                <div className="flex flex-wrap md:flex-nowrap gap-8 gy-3 gy-md-4 gy-lg-0 align-items-lg-center">
                    <div className="w-full md:w-1/2">
                        {/* Gallery */}
                        <div className="flex flex-wrap md:flex-nowrap gap-4 h-full">
                            <div className="w-full md:w-1/2 col-md-12 mb-4 mb-lg-0">
                                <img src="https://images.unsplash.com/photo-1495995424756-6a5a3f9e7543?q=80&w=2076&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" className="w-100 shadow-1-strong rounded mb-4" alt="Boat on Calm Water" />
                                <img src="https://images.unsplash.com/photo-1592085550638-e6bc180a731e?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" className="w-100 shadow-1-strong rounded mb-4" alt="Wintry Mountain Landscape" />
                            </div>
                            <div className="w-full md:w-1/2 mb-4 mb-lg-0">
                                <img src="https://images.unsplash.com/photo-1505226317007-67f4f53d8624?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" className="w-100 shadow-1-strong rounded mb-4" alt="Mountains in the Clouds" />
                                <img src="https://images.unsplash.com/photo-1524069290683-0457abfe42c3?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&quot;" className="w-100 shadow-1-strong rounded mb-4" alt="Boat on Calm Water" />
                            </div>
                        </div>
                        {/* Gallery */}
                    </div>
                    <div className="h-full w-full md:w-1/2 flex flex-col justify-center">
                        <div className="h-full flex flex-wrap md:flex-nowrap flex-col justify-center">
                            <div className="flex flex-wrap md:flex-nowrap flex-col justify-center">
                                <h2 className="text-2xl md:text-4xl font-semibold pb-1 mb-0">Take a step beyond CSR</h2>
                                <p className="text-[#b02027] fs-2 text-secondary mb-3 font-semibold">
                                    With Social Value Investing
                                </p>
                                <p className="mb-5" style={{ textAlign: 'justify' }}>Getscholify is a supporting platform
                                    revolutionizing the way how education is delivered to the minorities. The design of
                                    this plateform enebles Corporates and Colleges to help the needy meritorious
                                    students collectively. The monitory contribution of Corporate/Individual's combined
                                    with the scholarship assistance of Colleges supports the dream education of students
                                    in need.</p>
                                <div className="flex flex-wrap">
                                    <div className="w-full md:w-1/2">
                                        <div className="card border-0 bg-light p-4">
                                            <div className="card-body">
                                                <i className=" mb-3 fas fa-hands-helping bg-[#f5f5f5] text-[#b02027] text-3xl p-4 rounded-full" />
                                                <h5 className="card-title text-lg md:text-xl font-semibold">Supporting Minorities</h5>
                                                <p className="card-text">Getscholify revolutionizes education by supporting
                                                    minority students.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full md:w-1/2">
                                        <div className="card border-0 bg-light p-4 ">
                                            <div className="card-body">
                                                <i className=" mb-3 fas fa-university feature-icon  bg-[#f5f5f5] text-[#b02027] text-3xl p-4 rounded-full" />
                                                <h5 className="card-title text-lg md:text-xl font-semibold">Collaborating with Colleges</h5>
                                                <p className="card-text">Colleges and corporates collaborate to provide
                                                    financial assistance to needy students.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full md:w-1/2">
                                        <div className="card border-0 bg-light p-4">
                                            <div className="card-body">
                                                <i className=" mb-3 fas fa-hand-holding-heart feature-icon  bg-[#f5f5f5] text-[#b02027] text-3xl p-4 rounded-full" />
                                                <h5 className="card-title text-lg md:text-xl font-semibold">Social Impact</h5>
                                                <p className="card-text">Getscholify helps fulfill the dreams of students in
                                                    need, ensuring their journey to success.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full md:w-1/2">
                                        <div className="card border-0 bg-light p-4">
                                            <div className="card-body">
                                                <i className=" mb-3 fas fa-globe feature-icon  bg-[#f5f5f5] text-[#b02027] text-3xl p-4 rounded-full" />
                                                <h5 className="card-title text-lg md:text-xl font-semibold">Accessible Education</h5>
                                                <p className="card-text">Getscholify with thier scholarhips, is trying to make education reach every part of the nation</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


    );
};

export default Methodology;
