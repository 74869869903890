import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
const ScholarshipForm = ({ mail, userId, onClose }) => {
  const [formData, setFormData] = useState({
    actualName: mail,
    userId: userId,
    firstName: '',
    lastName: '',
    fatherName: '',
    contactNumber: '',
    dateOfBirth: '',
    passingYear: '',
    course: '',
    email: '',
    city: '',
    state: '',
    examinationAppeared: '',
    otherExaminationsAppeared: '',
    cgpaPercentile: '',
    familyAnnualIncome: '',
  });
  const [submitted, setSubmitted] = useState(false); // State to track whether form is submitted
  const [existingData, setExistingData] = useState(null); // State to store existing data

  useEffect(() => {
    const fetchExistingData = async () => {
      try {
        const response = await axios.get('https://getscholify-backend.onrender.com/api/student-scholarships/');
        const scholarshipData = response.data;
        const existingFormData = scholarshipData.find(data => data.userId === userId);
        if (existingFormData) {
          setExistingData(existingFormData);
          setSubmitted(true); // If existing data found, mark form as submitted
          // Fill form data with existing data
          setFormData(existingFormData);
        }
      } catch (error) {
        console.error('Error fetching existing scholarship data:', error);
      }
    };

    fetchExistingData();
  }, [userId]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://getscholify-backend.onrender.com/api/student-scholarships', formData);
      alert('Student scholarship submitted successfully!');
      // Clear form after successful submission
      setFormData({
        actualName: mail,
        userId: userId,
        firstName: '',
        lastName: '',
        fatherName: '',
        contactNumber: '',
        dateOfBirth: '',
        passingYear: '',
        course: '',
        email: '',
        city: '',
        state: '',
        examinationAppeared: '',
        otherExaminationsAppeared: '',
        cgpaPercentile: '',
        familyAnnualIncome: '',
      });
      setSubmitted(true); // Mark form as submitted
      console.log(mail);
    } catch (error) {
      console.error('Error submitting scholarship:', error);
      alert('Failed to submit student scholarship. Please try again.');
    }
  };
  return (
    <div class="mx-auto rounded-md shadow-md p-6 h-[80vh] overflow-auto">
      <div className='flex justify-end'><button onClick={onClose} className='bg-[#b02027] text-white text-lg rounded-md px-3 top-0 end-0'><i className='fa fa-times'></i></button></div>
      <p className="text-2xl px-8 font-bold text-rose-800 pb-3 text-[#cf2525]">Scholarship Form</p>
      <form class="bg-white rounded px-8 pt-6 mb-4" onSubmit={handleSubmit}>
        <div class="mb-4">

          <div class="mb-4 flex flex-wrap -mx-2">
            <div class="w-full md:w-1/2 px-2 mb-4 md:mb-0">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="first-name">First Name</label>
              <input
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="firstName" type="text" placeholder="First Name" value={formData.firstName} onChange={handleChange}
              />
            </div>
            <div class="w-full md:w-1/2 px-2">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="last-name">Last Name</label>
              <input
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="lastName" type="text" placeholder="Last Name" value={formData.lastName} onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
          <div class="mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="father-name">Father's Name</label>
            <input
              class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="fatherName" type="text" placeholder="Father's Name" value={formData.fatherName} onChange={handleChange}
            />
          </div>
          <div class="mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="contact-number">Contact Number</label>
            <input
              class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="contactNumber" type="text" placeholder="Contact Number" value={formData.contactNumber} onChange={handleChange}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div class="mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="date-of-birth">Date of Birth</label>
            <input
              class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="dateOfBirth" type="date" placeholder="dd/mm/yyyy" value={formData.dateOfBirth} onChange={handleChange}
            />
          </div>
          <div class="mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="passing-year">Passing Year</label>
            <input
              class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="passingYear" type="text" placeholder="Passing Year" value={formData.passingYear} onChange={handleChange}
            />
          </div>
        </div>
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
          <div class="mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="course">Select Your Course</label>
            <select
              class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="course" value={formData.course} onChange={handleChange}
            >

              <option value="Engineering">Engineering</option>
              <option value="Pharmacy">Pharmacy</option>
              <option value="Para-Medical">Para-Medical</option>
              <option value="BUsiness Management">Business Management</option>
              <option value="Computer Application">
                Computer Application
              </option>
              <option value="Food Agriculture &amp; Forestery">
                Food Agriculture &amp; Forestery
              </option>
              <option value="Hotel &amp; Tourism">Hotel &amp; Tourism</option>
              <option value="Education &amp; Teaching">
                Education &amp; Teaching
              </option>
              <option value="Dental">Dental</option>
              <option value="Mass Communication">Mass Communication</option>
              <option value="Nursing">Nursing</option>
            </select>

          </div>
          <div class="mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="email">Email</label>
            <input
              class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email" type="email" placeholder="Email" value={formData.email} onChange={handleChange}
            />
          </div>
        </div>
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="city">City</label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="city" type="text" placeholder="City" value={formData.city} onChange={handleChange}
          />
        </div>
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="state">State</label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="state" type="text" placeholder="State" value={formData.state} onChange={handleChange}
          />
        </div>
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="appeared-exam">Examination Appeared</label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="appearedExam" type="text" placeholder="Examination Appeared" value={formData.appearedExam} onChange={handleChange}
          />
        </div>
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="cgpa-percentile">CGPA / Percentile</label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="cgpaPercentile" type="text" placeholder="CGPA / Percentile" value={formData.cgpaPercentile} onChange={handleChange}
          />
        </div>
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="annual-income">Family's Annual Income</label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="annualIncome" type="text" placeholder="Family's Annual Income" value={formData.annualIncome} onChange={handleChange}
          />
        </div>
        <div class="flex items-center justify-center lg:justify-start mt-6">
          {!submitted && !existingData && (
            <button
              className="bg-red-800 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Submit Form
            </button>
          )}
        </div>
      </form>
    </div>

  );
};

export default ScholarshipForm;
