import React from 'react'

const Secondcomp = () => {
    return (
       <div className="bg-[#b02027] text-white rounded-md md:rounded-full p-3 px-8 w-fit mx-12 z-20 relative mb-[-30px] md:mx-auto">
        <p>Join hands with GetScholify to get scholarships for Higher Studies to improve the Indian Education Gross Enrollment</p>
       </div>
    )
}

export default Secondcomp