import { CurrencyDollarIcon, BookmarkSquareIcon, HandRaisedIcon } from '@heroicons/react/20/solid'

const features = [
    {
        name: 'Direct Scholarship Support:',
        description:
            'Your donation is directly allocated to scholarships for students in need. You can help them overcome financial barriers and pursue their academic dreams. Every dollar counts!',
        icon: CurrencyDollarIcon,
    },
    {
        name: 'Increased Access to Education: ',
        description: 'We strive to make scholarships accessible to a wider range of students. Your contribution helps us expand our reach and connect with even more deserving candidates.',
        icon: BookmarkSquareIcon,
    },
    {
        name: 'Building a Brighter Future: ',
        description: 'By investing in education, you are investing in the future of our society. These students will become leaders, innovators, and contributors who will make a positive impact for generations to come.',
        icon: HandRaisedIcon,
    },
]

export default function Example() {
    return (
        <div className="overflow-hidden bg-white py-24 sm:py-32">
            <div className="mx-auto w-10/12 px-6 lg:px-8">
                <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                    <div className="lg:pr-8 lg:pt-4">
                        <div className="lg:max-w-lg">
                            <h2 className="text-base font-semibold leading-7 text-[#b02027]">Contribute With Us and Make a Difference!</h2>
                            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">The Impact of Your Contribution:</p>
                            <p className="mt-6 text-sm  text-gray-600">
                                At Getscholify, we believe education is the key to unlocking a brighter future. Your contribution, no matter the size, can make a real difference in the lives of deserving students. Here's how you can partner with us and be a part of their success story:
                            </p>
                            <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                                {features.map((feature) => (
                                    <div key={feature.name} className="relative pl-9">
                                        <dt className="inline font-semibold text-gray-900">
                                            <feature.icon className="absolute left-1 top-1 h-5 w-5 text-[#b02027]" aria-hidden="true" />
                                            {feature.name}
                                        </dt>{' '}
                                        <dd className="inline">{feature.description}</dd>
                                    </div>
                                ))}
                            </dl>
                        </div>
                    </div>
                    <img
                        src="https://images.unsplash.com/photo-1555967522-37949fc21dcb?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        alt="Product screenshot"
                        className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
                        width={2432}
                        height={1442}
                    />
                </div>
            </div>
        </div>
    )
}
