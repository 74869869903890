import React, { useState, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import img1 from "../../Assets/iconss/process.jpg"
import img2 from "../../Assets/iconss/education.jpg"
import img3 from "../../Assets/iconss/future.jpg"



const OurAim = () => {
    const [activeSection, setActiveSection] = useState(0);
    const sliderRefs = [useRef(null), useRef(null)]; // Create refs for both sliders

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const sections = [
        {
            title: 'Students',
            image: img1,
            content: (
                <div className="flex">
                    <div className="w-full p-4">
                        <h2 className="text-3xl font-bold mb-4">Our simple and effective process</h2>
                        <p className="mb-4">Access a variety of resources and tools to enhance your learning experience. From interactive lessons to collaborative projects, Techlearns is designed to support your academic growth.</p>


                    </div>
                </div>
            ),
        },
        {
            title: 'Teachers',
            image: img2,
            content: (
                <div className="flex">
                    <div className="w-full p-4">
                        <h2 className="text-3xl font-bold mb-4">Make education affordable for everyone</h2>
                        <p className="mb-4">Create dynamic and interactive lessons with Techlearns' tools. Engage your students through multimedia content and foster a collaborative learning environment.</p>


                    </div>
                </div>
            ),
        },
        {
            title: 'Schools',
            image: img3,
            content: (
                <div className="flex">
                    <div className="w-full p-4">
                        <h2 className="text-3xl font-bold mb-4">Creating better future for you</h2>
                        <p className="mb-4">Optimize school workflows and operations with Techlearns. From admissions to alumni management, our platform is designed to support the diverse needs of educational institutions.</p>

                    </div>
                </div>
            ),
        },
        // Add more sections as needed
    ];

    const handleButtonClick = (index) => {
        setActiveSection(index);
        sliderRefs.forEach((ref) => {
            if (ref.current) {
                ref.current.slickGoTo(index);
            }
        });
    };

    return (
        <section className="bg-[#f8f8f8] py-8">
            <div className="container-fluid">
                <div className="w-10/12 mx-auto py-8">
                    <div className="flex flex-wrap md:flex-nowrap">
                        <div className="w-full">
                            <div className="horizontal-timeline w-full">
                                <ul className="flex flex-wrap md:flex-nowrap gap-4 list-style-none">
                                    <li className="w-full md:w-1/3 border-white">
                                        <div className="flex flex-wrap gap-3 items-center justify-normal md:justify-center">
                                            <i className="fa fa-graduation-cap border] border-2 rounded-full bg-[#b0202710] text-[#b02027] p-3 text-sm" />
                                            {/* <div class="event-date badge bg-danger">1.</div> */}
                                            <h5 className="text-sm text-[#b02027] font-semibold">Get Best Career Suggestions</h5>
                                        </div>
                                    </li>
                                    <li className="w-full md:w-1/3 border-white">
                                        <div className="flex flex-wrap gap-3 items-center justify-normal md:justify-center">
                                            <i className="fas fa-money-bill rounded-full bg-[#b0202710] text-[#b02027] p-3 text-sm" />
                                            <h5 className=" text-sm text-[#b02027] font-semibold">Free of Cost</h5>
                                        </div>
                                    </li>
                                    <li className="w-full md:w-1/3 border-white">
                                        <div className="flex flex-wrap gap-3 items-center justify-normal md:justify-center">
                                            <i className="fa fa-book rounded-full bg-[#b0202710] text-[#b02027] p-3 text-sm" />
                                            <h5 className=" text-sm text-[#b02027] font-semibold">Choose The Right Course</h5>
                                        </div>
                                    </li>
                                    <li className="w-full md:w-1/3">
                                        <div className="flex flex-wrap gap-3 items-center justify-normal md:justify-center">
                                            <i className="fa fa-envelope rounded-full bg-[#b0202710] text-[#b02027] p-3 text-sm " />
                                            <h5 className=" text-sm text-[#b02027] font-semibold">Easy and Transparent Procedure</h5>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
};

export default OurAim;
