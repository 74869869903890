import React from 'react'
import { Link } from 'react-router-dom'
const ToQueryButton = () => {
  return (
    <Link to="/Query">
        <button className="text-white bg-[#af0305] border-0 py-2 px-8 focus:outline-none transition duration-300 transform hover:-translate-y-1 hover:bg-[#af0305] mr-2 rounded-2xl text-lg my-2 sm:mb-0">
    HAVE A QUERY
  </button>
    </Link>
  )
}

export default ToQueryButton