const scholarships = [
    {
        type: "Getscholify Scholarships",
        scholarships: [
            {
                "_id": "1",
                "title": "Academic Merit Scholarship",
                "type": "Merit scholarship",
                "organisation": "GetScholify",
                "eligibility": "Students with outstanding academic performance",
                "amount": "INR 20,000 – 50,000 per annum",
                "description": "The Academic Merit Scholarship recognizes students who have demonstrated exceptional academic achievements.",
                "image": "https://getscholify-958e5.web.app/static/media/logo.198e392e1314e6c786cd.png"
            },
            {
                "_id": "2",
                "title": "Sports Merit Scholarship",
                "type": "Merit scholarship",
                "organisation": "GetScholify",
                "eligibility": "Students excelling in sports at the national or international level",
                "amount": "INR 30,000 – 60,000 per annum",
                "description": "The Sports Merit Scholarship is awarded to students who have shown outstanding performance in sports at the national or international level.",
                "image": "https://getscholify-958e5.web.app/static/media/logo.198e392e1314e6c786cd.png"
            },
            {
                "_id": "3",
                "title": "Defence Background Scholarship",
                "type": "Special scholarship",
                "organisation": "GetScholify",
                "eligibility": "Students from defence backgrounds",
                "amount": "INR 15,000 – 40,000 per annum",
                "description": "The Defence Background Scholarship supports students who come from defence backgrounds and aspire to pursue higher education.",
                "image": "https://getscholify-958e5.web.app/static/media/logo.198e392e1314e6c786cd.png"
            },
            {
                "_id": "4",
                "title": "Single Girl Child Scholarship",
                "type": "Special scholarship",
                "organisation": "GetScholify",
                "eligibility": "Single girl child in the family",
                "amount": "INR 25,000 – 55,000 per annum",
                "description": "The Single Girl Child Scholarship aims to empower and support single girl children in pursuing their education.",
                "image": "https://getscholify-958e5.web.app/static/media/logo.198e392e1314e6c786cd.png"
            }
        ]
    },
    {
        type: "Corporate/CSR Scholarships",
        scholarships:
            [
                {
                    "_id": "1",
                    "title": "HDFC Educational Crisis Scholarship Scheme",
                    "type": "Foundation scholarship",
                    "organisation": "HDFC Bank",
                    "eligibility": "Students from Class 6 to PG level facing a personal or financial crisis due to the death/loss of income of a parent/guardian",
                    "amount": "INR 10,000 – 25,000 per annum",
                    "description": "The HDFC Educational Crisis Scholarship Scheme is designed to provide financial assistance to students who are facing personal or financial crises due to the death or loss of income of a parent or guardian.",
                    "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/2/28/HDFC_Bank_Logo.svg/2560px-HDFC_Bank_Logo.svg.png"
                },
                {
                    "_id": "2",
                    "title": "Foundation for Academic Excellence and Access (FAEA) Scholarship",
                    "type": "Foundation scholarship",
                    "organisation": "Foundation for Academic Excellence and Access (FAEA)",
                    "eligibility": "Socially and economically disadvantaged students enrolled in UG courses in arts, commerce, science, engineering, and other professional courses",
                    "amount": "Covers the student’s tuition fees, maintenance allowance, books, and travel expenses",
                    "description": "The Foundation for Academic Excellence and Access (FAEA) Scholarship aims to support socially and economically disadvantaged students pursuing undergraduate courses in various fields. It covers tuition fees, maintenance allowance, books, and travel expenses.",
                    "image": "https://projectheena.com/uploads/ngo/35151731675391/profileImage/images/faea-india.jpg"
                },
                {
                    "_id": "3",
                    "title": "Adobe India Women-in-Technology Scholarship",
                    "type": "Corporate scholarship",
                    "organisation": "Adobe India",
                    "eligibility": "Female students pursuing UG or PG degrees in computer science, engineering, or related fields in India",
                    "amount": "Up to INR 5,00,000",
                    "description": "The Adobe India Women-in-Technology Scholarship is aimed at supporting and encouraging women in the field of technology.",
                    "image": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRyS4B1d6KC8g4bXE1flYNROBjG6WZsoWSp0lWlAyhfYg&s"
                },
                {
                    "_id": "4",
                    "title": "Infosys Foundation Leadership Program",
                    "type": "Corporate scholarship",
                    "organisation": "Infosys Foundation",
                    "eligibility": "Meritorious students from economically disadvantaged backgrounds pursuing UG or PG degrees in any field in India",
                    "amount": "Up to INR 1,00,000 (covers college tuition fees, related learning materials, accommodation and living expenses)",
                    "description": "The Infosys Foundation Leadership Program aims to identify and nurture leadership qualities in students from underprivileged backgrounds.",
                    "image": "https://give.do/static/img/logos/17HG/5b52a2a9-93cd-4619-a235-898e53422b6b.jpg"
                },
                {
                    "_id": "5",
                    "title": "Mahindra & Mahindra Merit Scholarship",
                    "type": "Corporate scholarship",
                    "organisation": "Mahindra & Mahindra",
                    "eligibility": "Meritorious students from economically disadvantaged backgrounds pursuing PG degrees in engineering, technology, or management in India",
                    "amount": "Up to INR 4,00,000 – 8,00,000 per scholar",
                    "description": "The Mahindra & Mahindra Merit Scholarship is designed to recognize and support academic excellence among students from economically disadvantaged backgrounds.",
                    "image": "https://seeklogo.com/images/M/mahindra-logo-827A745571-seeklogo.com.png"
                },
                {
                    "_id": "6",
                    "title": "Reliance Foundation Dhirubhai Ambani Scholarship",
                    "type": "Corporate scholarship",
                    "organisation": "Reliance Foundation",
                    "eligibility": "Meritorious students from economically disadvantaged backgrounds pursuing UG or PG degrees in engineering, technology, or medicine in India",
                    "amount": "Up to INR 41,000 per annum",
                    "description": "The Reliance Foundation Dhirubhai Ambani Scholarship aims to empower bright young minds by providing financial assistance to pursue higher education.",
                    "image": "https://upload.wikimedia.org/wikipedia/commons/a/ad/Reliance_Foundation_Logo.png"
                },
                {
                    "_id": "7",
                    "title": "Aditya Birla Foundation Scholarship",
                    "type": "Corporate scholarship",
                    "organisation": "Aditya Birla Foundation",
                    "eligibility": "Students from renowned institutes like XLRI, BITS, IIMs, and IITs",
                    "amount": "Upto INR 1.8 lakhs",
                    "description": "The Aditya Birla Foundation Scholarship recognizes academic excellence and provides support to students from top institutes in India.",
                    "image": "https://www.adityabirla.com/Assets/images/our-download-logo.png"
                },
                {
                    "_id": "8",
                    "title": "Tata Scholars Program",
                    "type": "Corporate scholarship",
                    "organisation": "Tata Group",
                    "eligibility": "Meritorious students pursuing UG and PG degrees in any field in India",
                    "amount": "Up to INR 1,000,000",
                    "description": "The Tata Scholars Program is aimed at supporting and nurturing talented individuals to become future leaders in their respective fields.",
                    "image": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_qNgw9Pm6KQdjFpiMwYFOsxzu_s1spOz6fdoCwHSHkw&s"
                },
                {
                    "_id": "9",
                    "title": "Indian Oil Academic Scholarship",
                    "type": "Corporate scholarship",
                    "organisation": "Indian Oil Corporation",
                    "eligibility": "Meritorious students from the EWS category wanting to pursue engineering, medical, and management courses",
                    "amount": "Upto INR 48,000 per annum",
                    "description": "The Indian Oil Academic Scholarship is designed to support students from economically weaker sections in pursuing higher education in specific fields.",
                    "image": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxRB_ygJIGiYKXLRoVFLPL34Z8LcknT5tXcCFZTjRvDQ&s"
                },
                {
                    "_id": "10",
                    "title": "CLP India Scholarship",
                    "type": "Corporate scholarship",
                    "organisation": "CLP India",
                    "eligibility": "Indian students from Classes 10 – 12, UG, PG, diploma, and vocational training courses in the regions of Maharashtra, Rajasthan, Gujarat, Karnataka, Telangana, Tamil Nadu, and Madhya Pradesh",
                    "amount": "Up to INR 1,00,000",
                    "description": "The CLP India Scholarship aims to provide financial assistance to deserving students pursuing education across various levels and disciplines.",
                    "image": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSkPauTb4S42D6D6cPMS1B5goi2zd7GzlYt8PqEKndF&s"
                },
                {
                    "_id": "11",
                    "title": "Coal India Limited Scholarship in India",
                    "type": "Corporate scholarship",
                    "organisation": "Coal India Limited",
                    "eligibility": "Indian students from economically disadvantaged families pursuing UG or PG courses in science, engineering, and technology",
                    "amount": "Up to INR 1,00,000 per year",
                    "description": "The Coal India Limited Scholarship provides support to students from economically disadvantaged backgrounds to pursue higher education in science, engineering, and technology fields.",
                    "image": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkOpdMLAm3vZrCvzd_EeKYmEKzBiFXJkMEl3KJp9gFrA&s"
                }
            ]
    },
    {
        type: "Government Scholarships",
        scholarships: [

            {
                "id": "1",
                "title": "National Overseas Scholarship Scheme for Scheduled Caste Students",
                "type": "Government scholarship",
                "description": "This scheme aims to provide financial assistance to meritorious students belonging to Scheduled Castes for pursuing higher studies abroad in fields like Engineering, Management, Pure Sciences, Agricultural Sciences, Medicine, etc.",
                "organisation": "Ministry of Social Justice and Empowerment, Government of India",
                "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Government_of_India_logo.svg/500px-Government_of_India_logo.svg.png",
                "eligibility": "Indian students belonging to Scheduled Castes, below 35 years of age, who have secured admission to a Master's or Ph.D. program in a foreign university.",
                "amount": "Covers tuition fees, maintenance, airfare, contingency allowance, and other incidental charges."
            },
            {
                "id": "2",
                "title": "Prime Minister's Scholarship Scheme for Central Armed Police Forces and Assam Rifles",
                "type": "Government scholarship",
                "description": "This scheme offers scholarships to the dependent wards/widows of Central Armed Police Forces and Assam Rifles personnel martyred in action for pursuing professional degree courses like Engineering, Medical, Dental, Veterinary, MBA, MCA, etc.",
                "organisation": "Ministry of Home Affairs, Government of India",
                "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Government_of_India_logo.svg/500px-Government_of_India_logo.svg.png",
                "eligibility": "Dependent wards/widows of personnel of CAPFs and AR who died in harness or due to attributable causes, pursuing professional degree courses in recognized institutions.",
                "amount": "Rs. 2500/- per month for girls and Rs. 2000/- per month for boys for a duration of 1 to 5 years."
            },
            {
                "id": "3",
                "title": "Dr. Ambedkar Post-Matric Scholarship for Economically Backward Classes (EBC)",
                "type": "Government scholarship",
                "description": "This scholarship aims to provide financial assistance to students belonging to Economically Backward Classes for pursuing post-matriculation studies at the higher secondary level and above.",
                "organisation": "Ministry of Social Justice and Empowerment, Government of India",
                "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Government_of_India_logo.svg/500px-Government_of_India_logo.svg.png",
                "eligibility": "Indian students belonging to EBC with an annual family income below a specified limit, pursuing studies beyond 10th class.",
                "amount": "Covers admission fees, tuition fees, and maintenance allowance."
            },
            {
                "id": "4",
                "title": "Maulana Azad National Fellowship for Minority Students",
                "type": "Government scholarship",
                "description": "This fellowship program provides financial assistance to minority students (Muslims, Christians, Sikhs, Buddhists, and Parsis) for pursuing M.Phil. and Ph.D. courses in Sciences, Humanities, Social Sciences, and Engineering & Technology.",
                "organisation": "Ministry of Minority Affairs, Government of India",
                "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Government_of_India_logo.svg/500px-Government_of_India_logo.svg.png",
                "eligibility": "Minority students who have passed the postgraduate examination with at least 55% marks or equivalent grade and are pursuing full-time research without any other scholarship.",
                "amount": "Fellowship amount up to Rs. 25,000/- per month for JRF and Rs. 28,000/- per month for SRF."
            },
            {
                "id": "5",
                "title": "National Talent Search Examination (NTSE) Scholarship",
                "type": "Government scholarship",
                "description": "NTSE is a national-level scholarship program to identify and nurture talented students in India. It awards scholarships to students pursuing courses in Sciences and Social Sciences up to doctoral level.",
                "organisation": "National Council of Educational Research and Training (NCERT), Government of India",
                "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Government_of_India_logo.svg/500px-Government_of_India_logo.svg.png",
                "eligibility": "Indian students studying in Class X and below the age of 18 years, who have secured a certain minimum percentage of marks in their respective state board examinations.",
                "amount": "Rs. 1250/- per month for Class XI and XII, Rs. 2000/- per month for UG and PG, and as per UGC norms for Ph.D. students."
            },
            {
                "id": "6",
                "title": "Central Sector Scheme of Scholarship for College and University Students",
                "type": "Government scholarship",
                "description": "This scheme provides financial assistance to meritorious students from low-income families to meet a part of their day-to-day expenses while pursuing higher studies.",
                "organisation": "Ministry of Education, Government of India",
                "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Government_of_India_logo.svg/500px-Government_of_India_logo.svg.png",
                "eligibility": "Indian students who have passed Class XII examination from a recognized board and are pursuing regular courses in colleges/universities.",
                "amount": "Rs. 10000/- per annum for the first three years and Rs. 20000/- per annum for the fourth and fifth years of study."
            },
            {
                "id": "7",
                "title": "INSPIRE Scholarship for Higher Education (SHE)",
                "type": "Government scholarship",
                "description": "SHE aims to attract talented youth into undertaking higher education courses in Science and Technology and offers scholarships for undertaking Bachelor's and Master's level education in natural/basic sciences.",
                "organisation": "Department of Science and Technology, Government of India",
                "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Government_of_India_logo.svg/500px-Government_of_India_logo.svg.png",
                "eligibility": "Indian students within the top 1% at Class XII examination conducted by respective Boards in the country pursuing courses in Natural and Basic Sciences at the B.Sc. or Integrated M.Sc. level.",
                "amount": "Rs. 80000/- per annum along with a summer time attachment fee of Rs. 20000/- for undertaking summer research projects."
            },
            {
                "id": "8",
                "title": "Post-Matric Scholarship Scheme for Minorities",
                "type": "Government scholarship",
                "description": "This scheme provides financial assistance to minority community students to enable them to complete matriculation and higher secondary education, and encourages them to continue their education at the post-matriculation level.",
                "organisation": "Ministry of Minority Affairs, Government of India",
                "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Government_of_India_logo.svg/500px-Government_of_India_logo.svg.png",
                "eligibility": "Indian students belonging to minority communities (Muslims, Christians, Sikhs, Buddhists, Parsis, and Jains) whose annual family income is below a specified limit and are studying in classes XI to Ph.D.",
                "amount": "Covers admission fees, tuition fees, maintenance allowance, and other incidental charges."
            },
            {
                "id": "9",
                "title": "Scholarship for Higher Education (SHE) for ST Students",
                "type": "Government scholarship",
                "description": "This scheme aims to support ST students to pursue higher education in natural/basic sciences at the B.Sc., M.Sc., and Integrated M.Sc. levels.",
                "organisation": "Ministry of Tribal Affairs, Government of India",
                "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Government_of_India_logo.svg/500px-Government_of_India_logo.svg.png",
                "eligibility": "Indian ST students within the top 10,000 in Joint Entrance Examination of JEE(Main) conducted by CBSE for admissions to IITs and pursuing courses in Natural and Basic Sciences at the B.Sc. or Integrated M.Sc. level.",
                "amount": "Rs. 80000/- per annum along with a summer time attachment fee of Rs. 20000/- for undertaking summer research projects."
            },
            {
                "id": "10",
                "title": "Pre-Matric Scholarship Scheme for Scheduled Tribes (ST) Students",
                "type": "Government scholarship",
                "description": "This scheme provides financial assistance to ST students studying in classes IX and X to improve their educational attainment and encourage them to continue their education.",
                "organisation": "Ministry of Tribal Affairs, Government of India",
                "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Government_of_India_logo.svg/500px-Government_of_India_logo.svg.png",
                "eligibility": "Indian ST students whose parents' income does not exceed a certain limit and who are studying in a government or recognized private school.",
                "amount": "Covers admission fees, tuition fees, and maintenance allowance."
            },

        ]
    }
]

export default scholarships