import React from 'react'
import Home from './Views/Home'
import About from './Views/About'
import WhyUs from './Views/Whyus'
import { Routes, Route } from 'react-router-dom'
import Contribute from './Views/Contribute'
import JoinOurTeam from './Views/JoinOurTeam'
import Scholarship from './Views/Scholarship'
import Student from './Views/Student'
import Admin from './Views/Admin'
import Login from './auth/Login'
import Register from './auth/Register'
import Individual from './Views/Individual'
import Corporate from './Views/Corporate'
import Courses from './Views/Courses'
import PrivacyPolicy from './Views/PrivacyPolicy'
import TermsAndConditions from './Views/TermsAndConditions'
import RefundPolicy from './Views/RefundPolicy'



const RouterOutlet = () => {
    return (
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/about-us' element={<About />} />
            <Route path='/why-us' element={<WhyUs />} />
            <Route path='/contribute-with-us' element={<Contribute />} />
            <Route path='/join-our-team' element={<JoinOurTeam />} />
            <Route path='/scholarship' element={<Scholarship />} />
            <Route path='/student' element={<Student />} />
            <Route path='/admin' element={<Admin />} />
            <Route path='/sign-in' element={<Login />} />
            <Route path='/register' element={<Register />} />
            <Route path='/individual' element={<Individual />} />
            <Route path='/corporate' element={<Corporate />} />
            <Route path='/courses/:id' element={<Courses />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/terms-conditions' element={<TermsAndConditions/>} />
            <Route path='/refund-policy' element={<RefundPolicy/>} />
        </Routes>
    )
}

export default RouterOutlet