import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap, faMoneyBill, faBook, faEnvelope } from '@fortawesome/free-solid-svg-icons';

// JSON data
const reasons = [
  {
    title: "Scholarship Upto 100%",
    description: "Our scholarship programs aim to support and nurture talent, providing financial assistance to those who exhibit academic excellence, leadership potential, and a commitment to making a positive impact in their respective fields.",
    icon: faGraduationCap
  },
  {
    title: "50+ Courses Offered",
    description: "Our institution proudly provides a broad spectrum of courses designed to cater to the varied interests and career aspirations of our students. From foundational courses that build a strong academic base to specialized programs that delve deep into specific fields.",
    icon: faMoneyBill
  },
  {
    title: "25+ Contributors",
    description: "Recognizing the importance of contributors is essential in fostering a culture of collaboration and acknowledging the myriad ways in which individuals can positively influence and contribute to the betterment of society as a whole.",
    icon: faBook
  },
];

const Usps = () => {
  return (
    <section className="bg-[#f9f9f9] py-8">
      <div className="container-fluid">
        <div className="w-10/12 mx-auto py-8">
          <div className="flex flex-wrap md:flex-nowrap gap-8">
            {reasons.map((reason, index) => (
              <div key={index} className="w-full md:w-1/3 border-white">
                <div className="flex flex-wrap gap-4 md:flex-nowrap justify-normal">
                  <FontAwesomeIcon icon={reason.icon} className="rounded-full bg-[#b0202710] p-3 text-lg md:text-3xl text-[#b02027]" />
                  <div>
                    <h5 className="text-xl md:text-xl text-[#b02027] font-semibold pb-4">{reason.title}</h5>
                    <p className='text-sm'>{reason.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Usps;
