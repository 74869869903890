import React, { createContext, useContext, useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../firebase/firebase';

// Create authentication context
const AuthContext = createContext();

// Custom hook to use authentication context
export function useAuth() {
    return useContext(AuthContext);
}

// Authentication provider component
export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState(null);
    const [userLoggedIn, setUserLoggedIn] = useState(false); // Set default to false
    const [loading, setLoading] = useState(true);

    // Effect hook to initialize user and set up auth state change listener
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, initializeUser);
        return unsubscribe; // Unsubscribe from auth state changes when component unmounts
    }, []); // Empty dependency array ensures this effect runs only once on component mount

    // Function to initialize user based on auth state change
    async function initializeUser(user) {
        if (user) {
            setCurrentUser(user);
            setUserLoggedIn(true);
        } else {
            setCurrentUser(null);
            setUserLoggedIn(false);
        }
        setLoading(false); // Set loading to false once initialization is done
    }

    // Authentication context value
    const value = {
        currentUser,
        userLoggedIn,
        loading
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children} {/* Render children only when loading is false */}
        </AuthContext.Provider>
    );
}
