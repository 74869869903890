import React from "react";

const AboutUs = () => {
  return (
    <div className="py-12 w-10/12 mx-auto">
      <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-8 md:gap-4 gap-3 py-12">
        <div className="grid grid-cols-1 gap-4 ">
          <div>
            <img
              src="https://utfs.io/f/ca4d0f52-7bce-4138-8104-6a1efc6ab232-c5nrok.avif"
              alt=""
              srcSet=""
              width={"100%"} 
              className="rounded-md shadow-md" style={{borderRadius:"10% 0% 10% 0% "}}
            />
          </div>
        </div>
        <div className="flex flex-col justify-center">
          <p className="text-2xl md:text-4xl font-semibold">
            Getscholify strives to make higher education accessible for everyone
          </p>
         
        
          <p className="text-justify pt-6">
            <span className="text-[#AF0305] pb-4">
              Getscholify is a bridge between students, educators, and
              corporates. It is a digital platform designed to help the students
              of India with scholarships. The students getting scholarships to
              pursue their dream careers.
            </span>{" "}
            <br />
            
          </p>
          <p className="pt-5">
          Getscholify allows corporates & individuals to contribute to the
            education of these deserving children, and in doing that we are
            keeping everything transparent with the contributors. Donors can
            track their donations and can also get information about the
            scholarship recipient including the institution where students are
            studying.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
