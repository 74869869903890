import React, { useState, useEffect } from 'react';
import ScholarshipForm from './ScholarshipForm';
import ScholarshipResult from './ScholarshipResult';
import ProgressDashboard from './ProgressDashboard';
import WelcomeHeader from './WelcomeHeader';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ProfileForm from './ProfileForm';
import { doSignOut } from '../../firebase/auth';
import { useNavigate } from 'react-router-dom/dist';
const DashBoard = ({ mail, userId, firstName, email, image, signout }) => {
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState('form');
  const [showFormModal, setShowFormModal] = useState(false);
  const [showFormModal2, setShowFormModal2] = useState(false);
  const [data, setData] = useState('')
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleFormModalToggle = () => {
    setShowFormModal(!showFormModal);
  };
  const handleFormModalToggle2 = () => {
    setShowFormModal2(!showFormModal2);
  };

  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(true); // State for loading indicator

  useEffect(() => {
    const fetchScholarshipData = async () => {
      try {
        const response = await axios.get(`https://getscholify-backend.onrender.com/api/student-scholarships/`);
        const scholarshipData = response.data;
        // Find the scholarship data for the provided userId
        const userScholarship = scholarshipData.find(scholarship => scholarship.userId === userId);
        if (userScholarship) {
          setStatus(parseInt(userScholarship.status));
          setProgress(parseInt(userScholarship.status)); // Set progress directly from status
        } else {
          setStatus('0');
        }
      } catch (error) {
        console.error('Error fetching scholarship data:', error);
      } finally {
        setLoading(false); // Set loading state to false after fetching data
      }
    };

    fetchScholarshipData();
  }, [userId]);

  const [scholarshipStatus, setScholarshipStatus] = useState(0); // Default status is 0
  // const [loading, setLoading] = useState(true); // State for loading indicator

  useEffect(() => {
    const fetchScholarshipStatus = async () => {
      try {
        const response = await axios.get(`https://getscholify-backend.onrender.com/api/student-scholarships/`);
        const scholarshipData = response.data;
        const userScholarship = scholarshipData.find(scholarship => scholarship.userId === userId);
        setData(userScholarship)
        if (userScholarship) {
          setScholarshipStatus(userScholarship.status);
        } else {
          setScholarshipStatus(-1); // Indicate that data is not found for the user
        }
      } catch (error) {
        console.error('Error fetching scholarship data:', error);
      } finally {
        setLoading(false); // Set loading state to false after fetching data
      }
    };

    fetchScholarshipStatus();
  }, [userId]);



  function CardWithLink() {
    return (
      <div className="w-10/12 mx-auto rounded-md shadow-md">
        <p className="text-sm font-bold">Alert</p>
      </div>
    );
  }



  const handleDownload = async () => {
    try {
      const response = await axios.get('https://getscholify-backend.onrender.com/api/letters/all');
      const letterData = response.data;

      // Find the letter with the same userId
      const userLetter = letterData.find(letter => letter.userId === userId);

      // If the letter is found, download it
      if (userLetter && userLetter.filePath) {
        window.open(`https://getscholify-backend.onrender.com/${userLetter.filePath}`, '_blank');
      } else {
        console.error('Letter not found for user ID:', userId, userLetter.filePath);
      }
    } catch (error) {
      console.error('Error fetching letter data:', error);
    }
  };

  const renderStatusMessage = () => {
    switch (scholarshipStatus) {
      case 0:
        return <div className="rounded p-4 shadow-sm bg-[#b02027] text-white my-4">
          <p className="text-3xl font-semibold pb-5">
            Scholarship Result:
          </p>
          {/* <p>
                              Application is still under review! Kindly Wait for completion of procedure.
                          </p> */}

          <p className='pb-5'>
            You've Not Applied For A Sholarship   </p>
        </div>
      case 25:
        return <div className="rounded p-4 shadow-sm bg-[#b02027] text-white my-4">
          <p className="text-3xl font-semibold pb-5">
            Scholarship Result:
          </p>
          {/* <p>
                              Application is still under review! Kindly Wait for completion of procedure.
                          </p> */}

          <p className='pb-5 text-xl'>
            You've Applied For A Sholarship   </p>
        </div>
      case 50:
        return <div className="rounded p-4 shadow-sm bg-[#b02027] text-white my-4">
          <p className="text-3xl font-semibold pb-5">
            Scholarship Result:
          </p>
          {/* <p>
                            Application is still under review! Kindly Wait for completion of procedure.
                        </p> */}

          <p className='pb-5 text-xl'>
            Youre Scholarship Has Been Sent For Reviewing & Verification   </p>
        </div>
      case 75:
        return <div className="rounded p-4 shadow-sm bg-[#b02027] text-white my-4">
          <p className="text-3xl font-semibold pb-5">
            Scholarship Result:
          </p>
          {/* <p>
                              Application is still under review! Kindly Wait for completion of procedure.
                          </p> */}

          <p className='pb-5 text-xl'>
            Your Application is under review by the Review Committee  </p>
        </div>
      case 100:
        return (
          <div className="rounded p-4 shadow-sm bg-[#b02027] text-white my-4">
            <p className="text-3xl font-semibold pb-5">
              Scholarship Result:
            </p>
            {/* <p>
                                  Application is still under review! Kindly Wait for completion of procedure.
                              </p> */}
            <p className="text-2xl font-bold">
              Congratulations!
            </p>
            <p className='pb-5'>
              You've been successfully granted a scholarship
            </p>
            <button
              onClick={handleDownload}
              className="mt-4 bg-white text-[#b02027] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Download Letterhead and Scholarship Certificate <i className='fa fa-download'></i>
            </button> </div>

        );
      case -1:
        return <div className="rounded p-4 shadow-sm bg-[#b02027] text-white my-4">
          <p className="text-3xl font-semibold pb-5">
            Scholarship Result:
          </p>
          {/* <p>
                            Application is still under review! Kindly Wait for completion of procedure.
                        </p> */}

          <p className='pb-5'>
            You've Not Applied For A Sholarship   </p>
        </div>
      default:
        return null; // Default case, nothing to render
    }
  };

  return (
    <div className="w-10/12 mx-auto">
      <div className="flex flex-wrap gap-x-0 gap-y-3 md:gap-3 md:flex-nowrap ">
        <div className="w-full md:w-2/3">
          <div className="profile-intro">
            <div className="w-full mx-auto rounded shadow my-4 bg-[#b02027] h-[30vh]">
              <div className="flex flex-wrap h-full">
                <div className="w-full md:w-3/4 text-white flex flex-col justify-between p-4">
                  <p>
                    {userId}
                  </p>
                  <div>
                    <p className="text-4xl font-semibold pb-0 mb-0">
                      Welcome Back {firstName}!
                    </p>
                    <p className="font-semibold">Always Stay Updated With Your Portal</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap md:flex-nowrap gap-3 ">


              <div className="w-full md:w-2/3" onClick={handleFormModalToggle}>
                <div className="bg-white rounded p-8 w-100 shadow-md h-full flex flex-col justify-between align-items-start">
                  <p className="text-3xl font-semibold">
                    Fill/Review Your Application Form
                  </p>

                  <div>
                    <p className="text-xl font-semibold">
                      Click Here To
                    </p>
                    <button className="bg-white text-[#b02027] border-0 px-0">View Your Application Form <i className="fa fa-arflex flex-wrap-right" /></button>

                  </div>
                </div>
              </div>
              {showFormModal && (
                <div className="fixed inset-0  flex justify-center items-center bg-gray-800 bg-opacity-50">
                  <div className="bg-white p-8 h-[80vh] overflow-auto rounded-md">
                    <div className='flex justify-end'>
                      <button className=" text-white bg-[#b02027] text-lg px-3 rounded-md mb-4" onClick={handleFormModalToggle}>
                        <i className="fas fa-times"></i>
                      </button>
                    </div>
                    {
                      status === 0 ? (<ScholarshipForm userId={userId} mail={mail} onClose={handleFormModalToggle} />) :
                        status >= 25 ? <div className="overflow-x-auto">
                          <table className="min-w-full h-[80vh] overflow-auto divide-y divide-gray-200">

                            <thead className="bg-gray-50">
                              <tr>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Field
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Value
                                </th>
                              </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                              {Object.entries(data).map(([key, value]) => (
                                <tr key={key}>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {key}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {value}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div> : <ScholarshipForm userId={userId} mail={mail} onClose={handleFormModalToggle} />
                    }
                  </div>
                </div>
              )}

              <div className="w-full md:w-1/3">
                <div className="bg-white shadow-md rounded-md p-3 h-100">
                  <img src="https://images.unsplash.com/photo-1450101499163-c8848c66ca85?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt srcSet className="object-fit-cover rounded w-100" />
                  <p className="fs-5 font-semibold py-2 mb-1">
                    Application Progress Status :
                  </p>
                  <p className="text-[#b02027] fw-bold fs-5">{status
                  }%</p>
                  <div className="progress" style={{ height: 5 }}>
                    <div className='rounded-lg bg-[#b02027] h-[5px] ' style={{ width: `${progress}%` }}>

                    </div>
                  </div>
                  <p className='font-semibold pt-5'><span className='block text-[#b02027]'>Status:</span> {
                    status === 0 ? 'Application Not Submitted' :
                      status === 25 ? 'Application Submitted' :
                        status === 50 ? 'Under Review' :
                          status === 100 ? 'Scholarship Granted, Go to Result to Download Certificate' :
                            'Application not Submitted'
                  }</p>

                </div>
              </div>

            </div>
            <div className="w-full">
              {(renderStatusMessage())}
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/3">
          <div className="rounded-sm my-4 w-full p-4 shadow-md bg-white h-[30vh] flex flex-col justify-between items-start" onClick={handleFormModalToggle2}>
            <p className="text-3xl font-semibold pb-4">
              Complete Your Profile
            </p>
           
            <div>
              <p className="fs-6 font-semibold">
                Click Here To
              </p>
              <button className="bg-white text-[#b02027] border-0 px-0">Complete Your Profile <i className="fa fa-arflex flex-wrap-right" /></button>
            </div>


          </div>
          {showFormModal2 && (
            <div className="fixed inset-0  flex justify-center items-center bg-gray-800 bg-opacity-50">
              <div className="bg-white p-8 h-[80vh] overflow-auto rounded-md">
                <div className='flex justify-end'>
                  <button className=" text-white bg-[#b02027] text-lg px-3 rounded-md mb-4" onClick={handleFormModalToggle2}>
                    <i className="fas fa-times"></i>
                  </button>
                </div>
                {
                  <ProfileForm userId={userId} image={image} />
                }
              </div>
            </div>
          )}
          <div className="rounded my-4 w-100 p-4 shadow-sm bg-[#b02027] text-white">
            <p className="text-4xl font-semibold mb-4">
              Quick Links
            </p>
            <a href="tel:8002178800">
              <p className="font-semibold pb-3 border-bottom border-white">
                Contact Us
              </p>
            </a>
            <Link to='/scholarship'>
              <p className="font-semibold pb-3 border-bottom border-white">
                Browse Scholarships
              </p></Link>
            <a href="tel:8002178800
            ">
              <p className="font-semibold pb-3 border-bottom border-white">
                Reach Support
              </p>
            </a>
            <Link to='/contribute-with-us'>
              <p className="font-semibold pb-3 border-bottom border-white">
                Contribute With Us
              </p></Link>

            <button className='w-100 bg-white text-[#b02027] px-4 py-2 rounded-md' onClick={()=>doSignOut().then(()=>{navigate('/')})}>Sign Out</button>
          </div>
        </div>
      </div>
    </div>


  );
};

export default DashBoard;
