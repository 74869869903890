import React from 'react'
import AboutHeader from '../Components/About/AboutHeader'
import Prosper from '../Components/About/Prosper'
import Strives from '../Components/About/Strives'
import Ourusps from '../Components/About/Ourusps'
import Vision from '../Components/About/Vision'
import Aboutus from '../Components/About/AboutGetscholify'
import Philosophy from '../Components/About/Philosophy'
import Data from '../Components/About/Data'
import Process from '../Components/About/Process'
import Secondsection from '../Components/About/Secondcomp'
import Secondcomp from '../Components/About/Secondcomp'
const About = () => {
    return (
        <div>
            <AboutHeader />
            <Secondcomp/>
            <Data />
            {/* <Process /> */}
            <Philosophy /> 
            <Prosper />
            {/* <Ourusps /> */}
            <Vision />
            <Strives />
            
            {/* <Aboutus /> */}
        </div>
    )
}

export default About
