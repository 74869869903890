import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDatabase, faMapMarkerAlt, faClipboardCheck, faUserGroup, faNoteSticky } from '@fortawesome/free-solid-svg-icons';

const Steps = () => {
    return (
        <section class=" py-12 sm:py-16 lg:py-20 xl:py-24">
            <div class="mx-auto w-10/12 px-4 sm:px-6 lg:px-8">
                <div class="text-center">
                    <p class="text-sm font-bold uppercase tracking-widest text-gray-700">How It Works</p>
                    <h2 class="mt-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">Contribute to Scholarships</h2>
                    <p class="mx-auto mt-4 text-lg font-normal text-gray-700 lg:text-xl lg:leading-8">Join us in making education accessible by contributing to scholarships in just a few easy steps.</p>
                </div>
                <ul class="mx-auto mt-12 grid grid-cols-1 gap-10 sm:mt-16 lg:mt-20 w-full lg:grid-cols-3">
                    <li class="flex-start group relative flex lg:flex-col">
                        <div
                            class="inline-flex h-20 w-20 shrink-0 items-center justify-center rounded-full border bg-gray-200 transition-all duration-200 text-[#b02027]">
                            <FontAwesomeIcon icon={faClipboardCheck} class="h-10 w-10" />
                        </div>
                        <div class="ml-6 lg:ml-0 lg:mt-10">
                            <h3 class="text-xl font-bold text-gray-900">Fill the Contribution Form</h3>
                            <h4 class="mt-2 text-base text-gray-700">Start by filling out the contribution form with your details.</h4>
                        </div>
                    </li>
                    <li class="flex-start group relative flex lg:flex-col">
                        <div
                            class="inline-flex h-20 w-20 shrink-0 items-center justify-center rounded-full border bg-gray-200 transition-all duration-200 text-[#b02027]">
                            <FontAwesomeIcon icon={faUserGroup} class="h-10 w-10" />
                        </div>
                        <div class="ml-6 lg:ml-0 lg:mt-10">
                            <h3 class="text-xl font-bold text-gray-900">We Reach Out to You</h3>
                            <h4 class="mt-2 text-base text-gray-700">Our team will reach out to you for further details and coordination.</h4>
                        </div>
                    </li>
                    <li class="flex-start group relative flex lg:flex-col">
                        <div
                            class="inline-flex h-20 w-20 shrink-0 items-center justify-center rounded-full border bg-gray-200 transition-all duration-200 text-[#b02027]">
                            <FontAwesomeIcon icon={faNoteSticky} class="h-10 w-10" />
                        </div>
                        <div class="ml-6 lg:ml-0 lg:mt-10">
                            <h3 class="text-xl font-bold text-gray-900">Track Your Contribution</h3>
                            <h4 class="mt-2 text-base text-gray-700">You can track the progress of your contribution as it reaches deserving students.</h4>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    )
}

export default Steps;
