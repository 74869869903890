import React from "react";
import "../../Styling/Prosper.css";
import prosper from '../../Assets/Images/img-01.jpg'

const Data = () => {
  return (
    <div className="bg-gray-100">
      <section className="main-header pt-24 pb-16 ">
        <div className="w-10/12 mx-auto  flex flex-col lg:flex-row lg:gap-8 md:gap-4 gap-3">
          <div className="lg:w-1/2 flex items-center gap-x-16">
            <div className="custom-container">
              <div className="flex flex-col justify-center">
                <div className="flex md:mb-5 items-center">
                  <span className="border-l-2 border-[#b02027] h-6 mr-3"></span>
                  <h1 className="md:text-lg text-sm font-bold">ABOUT</h1>
                </div>
                <div className="md:text-5xl text-[20px] font-semibold mb-8">
                  <h1 className="text-[#b02027] text-[30px] md:text-7xl py-3">
                  About
                  </h1>
                   Getscholify
                </div>
                <p>
                <span className="border-l-2 border-[#b02027] h-6  "></span>
                The goal of education is the advancement of knowledge and the dissemination of truth.
                </p>
                <div className="my-5 py-2 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4">
                  <div>
                    <div className="header-card">
                      <h4 className="text-2xl text-[#b02027]">Mission Statement</h4>
                      <p className="text-sm mt-3 text-slate-700">
                      Empowering students with vital skills through college-corporate partnerships.
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="header-card">
                      <h4 className="text-2xl text-[#b02027] ">Origin Story</h4>
                      <p className="text-sm mt-3 text-slate-700">Born amidst the COVID-19 challenges, Getscholify is dedicated to aiding college aspirants.</p>
                    </div>
                  </div>
                  <div>
                    <div className="header-card">
                      <h4 className="text-2xl text-[#b02027]">Scholarship Initiative</h4>
                      <h6 className="text-sm mt-3 text-slate-700">
                      Getscholify: Empowering passions, unlocking potential. Choose your college, your path, with our support.
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:w-1/2  grid-cols-2  px-4 sm:mt-0">
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 md:grid-cols-2  ">
              <div className="grid gap-4 ">
                <div className="">
                  <img
                  src="https://utfs.io/f/22fb5baa-93bc-4e60-bf5e-0b468d243109-740r0s.avif"
                    className="h-[170px] w-full rounded-lg object-cover object-center"
                    
                  />
                </div>
                <div className=" ">
                  <img
                   src="https://utfs.io/f/9f8f3c80-0d46-49bb-aca0-a629c7de7bfc-nwiuif.avif"
                    className="h-[400px] w-full rounded-lg object-cover object-center"
                  />
                </div>
              </div>
              <div className="grid gap-4">
                <div >
                  <img
                  src="https://utfs.io/f/37ec17c6-c131-40e0-93bd-e4952ca88ce6-namfiy.jpg"
                    className="h-[450px]  w-full rounded-lg object-cover object-center"
                    
                  />
                </div>
                <div>
                  <img
                  src="https://utfs.io/f/83a3fe15-9e9d-4d09-9658-4875a006f730-rhoi1k.jpg"
                    className="h-[120px] w-full rounded-lg object-cover object-center"
                  />
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Data;
