import React from 'react'
import Individuaheader from '../Components/Individual/IndividualHeader'
import IndividualForm from '../Components/Individual/IndividualForm'

const Individual = () => {
    return (
        <div>
            <Individuaheader />
            <IndividualForm />
        </div>
    )
}

export default Individual