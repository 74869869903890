import React, { useState, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import scholarships from '../../scholarshipData';
import { Link } from 'react-router-dom/dist';

const Testimonials = () => {
    const [activeSection, setActiveSection] = useState(0);
    const sliderRef = useRef(null);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const handleButtonClick = (index) => {
        setActiveSection(index);
        sliderRef.current.slickGoTo(index);
    };

    return (
        <div className="w-10/12 m-auto py-8">
            <div className="pb-4">
                <p className="text-3xl md:text-3xl font-semibold px-4 border-[#b02027] border-l-4">
                    Our Featured Scholarships
                </p>
            </div>
            <div className="w-full m-auto mt-8 h-fit">
                <div className="mb-4 flex-col flex md:flex-wrap text-center md:flex-row md:justify-between">
                    {scholarships.map((section, index) => (
                        <button
                            key={index}
                            onClick={() => handleButtonClick(index)}
                            className={`px-4 py-2  text-lg cera-med ${activeSection === index
                                ? 'text-white  bg-[#b02027]'
                                : 'bg-transparent text-[#b02027]'
                                } rounded focus:outline-none`}
                        >
                            {section.type}
                        </button>
                    ))}
                </div>
                <Slider ref={sliderRef} {...settings}>
                    {scholarships.map((section, index) => (
                        <div key={index} className="outline-none flex flex-col justify-center p-4 h-fit">
                            {section.scholarships.slice(0, 4).map((scholarship, i) => (
                                <div key={i} className="p-4 flex flex-wrap md:flex-nowrap gap-5 shadow-md rounded-md mb-4">

                                    <div className='w-full md:w-1/5 flex items-center'>
                                        <img src={scholarship.image} alt={scholarship.title} className=" mx-auto  object-contain h-[10vh] " />
                                    </div>
                                    <div className='w-full md:w-4/5'>
                                        <h2 className="text-2xl font-bold mb-4">{scholarship.title}</h2>
                                        <p className="text-lg">{scholarship.description}</p>
                                        <p className="text-sm text-gray-500 mt-2">Organisation: {scholarship.organisation}</p>
                                        <p className="text-sm text-gray-500">Eligibility: {scholarship.eligibility}</p>
                                        <div className='flex justify-between items-center flex-wrap'>
                                            <p className="text-sm text-gray-500">Amount: {scholarship.amount}</p>
                                            <Link to='/student'><p className='text-[#b02027]'>Apply Now <i className='fa fa-arrow-right'></i></p></Link>
                                        </div>
                                    </div>

                                </div>
                            ))}
                        </div>
                    ))}
                </Slider>
                <div className="pb-4">
                    <Link to='/scholarship'>
                        <p className="text-3xl md:text-xl font-semibold px-4 text-center">
                            View More <i className='fa fa-arrow-right text-[#b02027]'></i>
                        </p></Link>

                </div>
            </div>

        </div>
    );
};

export default Testimonials;
