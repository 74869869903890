import React from 'react'
import ContributeHeader from '../Components/Contribute/ContributeHeader'
import Advantages from '../Components/Contribute/Advantages'
import Values from '../Components/Contribute/Values'
import Advantagecard from '../Components/Contribute/Advantagecard'
import Help from '../Components/Contribute/Help'
import Secondcomp from '../Components/Contribute/Secondcomp'
import Corporate from "../Components/Contribute/Corporate"
import Advantage from '../Components/Contribute/Advantage'
import Steps from "../Components/Contribute/Steps"
import Example from '../Components/Contribute/Example'
const Contribute = () => {
    return (
        <div>
            <ContributeHeader />
            <Secondcomp />
            <Advantages />
            <Advantagecard />
            <Advantage />
            <Steps />
            <Values />
            <Example/>
            {/* <Help />
            <Corporate /> */}
        </div>
    )
}

export default Contribute