import React from 'react'
import axios from 'axios';
import { useEffect, useState } from 'react';
const StudentScholarshipStatus = () => {
  const [scholarships, setScholarships] = useState([]);
  const [editingScholarship, setEditingScholarship] = useState(null);

  // Fetch scholarship data from the backend API
  useEffect(() => {
    const fetchScholarships = async () => {
      try {
        const response = await axios.get('https://getscholify-backend.onrender.com/api/scholarships');
        setScholarships(response.data);
      } catch (error) {
        console.error('Error fetching scholarships:', error);
      }
    };

    fetchScholarships();
  }, []);

  const handleEdit = (scholarship) => {
    setEditingScholarship(scholarship);
  };

  const handleCancelEdit = () => {
    setEditingScholarship(null);
  };

  const handleSaveEdit = async (updatedScholarship) => {
    try {
      await axios.put(`https://getscholify-backend.onrender.com/api/scholarships/${updatedScholarship._id}`, updatedScholarship);
      // Update scholarships state with the updated scholarship
      const updatedScholarships = scholarships.map(scholarship =>
        scholarship._id === updatedScholarship._id ? updatedScholarship : scholarship
      );
      setScholarships(updatedScholarships);
      setEditingScholarship(null);
    } catch (error) {
      console.error('Error updating scholarship:', error);
    }
  };

  const handleDelete = async (scholarshipId) => {
    try {
      await axios.delete(`https://getscholify-backend.onrender.com/api/scholarships/${scholarshipId}`);
      // Remove the deleted scholarship from the scholarships state
      const updatedScholarships = scholarships.filter(scholarship => scholarship._id !== scholarshipId);
      setScholarships(updatedScholarships);
    } catch (error) {
      console.error('Error deleting scholarship:', error);
    }
  };

  return (
    <div className="container mx-auto">
      <h1 className="text-3xl font-semibold mb-4">Scholarship Status</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {scholarships.map((scholarship, index) => (
          <div key={index} className="bg-white rounded-lg shadow-md p-4">
            {editingScholarship && editingScholarship._id === scholarship._id ? (
              <div>
                <input
                  type="text"
                  value={editingScholarship.title}
                  onChange={(e) => setEditingScholarship({ ...editingScholarship, title: e.target.value })}
                  className="border rounded-md px-2 py-1 mb-2"
                />
                <textarea
                  value={editingScholarship.description}
                  onChange={(e) => setEditingScholarship({ ...editingScholarship, description: e.target.value })}
                  className="border rounded-md px-2 py-1 mb-2"
                ></textarea>
                <input
                  type="text"
                  value={editingScholarship.eligibility}
                  onChange={(e) => setEditingScholarship({ ...editingScholarship, eligibility: e.target.value })}
                  className="border rounded-md px-2 py-1 mb-2"
                />
                <div className="flex justify-end">
                  <button onClick={() => handleSaveEdit(editingScholarship)} className="bg-blue-500 text-white px-4 py-2 rounded mr-2">Save</button>
                  <button onClick={handleCancelEdit} className="bg-gray-500 text-white px-4 py-2 rounded">Cancel</button>
                </div>
              </div>
            ) : (
              <div>
                <h2 className="text-xl font-semibold mb-2">{scholarship.title}</h2>
                <p className="text-gray-600 mb-2">{scholarship.description}</p>
                <p className="text-sm font-semibold">Eligibility: {scholarship.eligibility}</p>
                <div className="flex justify-end mt-2">
                  <button onClick={() => handleEdit(scholarship)} className="bg-blue-500 text-white px-4 py-2 rounded mr-2">Edit</button>
                  <button onClick={() => handleDelete(scholarship._id)} className="bg-red-500 text-white px-4 py-2 rounded">Delete</button>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default StudentScholarshipStatus