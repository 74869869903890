import { Timeline, TimelineItem, TimelineConnector, TimelineIcon, Typography, TimelineHeader } from "@material-tailwind/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap, faMoneyBillWave, faAward, faBook } from '@fortawesome/free-solid-svg-icons';
import image from '../../Assets/Uspback.jpg'
export default function Edge() {
  return (
    <section className="py-8">
      <div className="w-10/12 mx-auto">
        <div className="flex flex-wrap md:flex-nowrap gap-8">
          <div className="w-full md:w-1/2">
            <div className="w-full h-full">
              
             <img src={image} alt="" srcset="" className="object-cover h-full " style={{borderRadius:"10% 0% 10% 0% "}}/>
            </div>
          </div>

          <div className="w-full md:w-1/2">
            <div className="w-full">
            <p className="text-3xl font-bold pb-8">
                The GetScholify <span className="text-[#b02027]">Edge</span>
              </p>
              <Timeline>
                
                
                <TimelineItem className="h-32">
                  <TimelineHeader className="relative rounded-xl border border-blue-gray-50 bg-white py-3 pl-4 pr-8 shadow-lg shadow-blue-gray-900/5">
                    <TimelineIcon className="p-3" variant="ghost" color="">
                      <FontAwesomeIcon icon={faMoneyBillWave} className="h-5 w-5 text-[#b02027]" />
                    </TimelineIcon>
                    <div className="flex flex-col gap-1">
                      <Typography variant="h6" color="blue-gray">
                        <del>Scholarships are difficult to apply</del>
                      </Typography>
                      <Typography variant="h5" color="gray" className="font-normal">
                        Apply scholarships easily from your phone through getscholify
                      </Typography>
                    </div>
                  </TimelineHeader>
                </TimelineItem>
                <TimelineItem className="h-32">
                  <TimelineConnector className="!w-[78px]" />
                  <TimelineHeader className="relative rounded-xl border border-blue-gray-50 bg-white py-3 pl-4 pr-8 shadow-lg shadow-blue-gray-900/5">
                    <TimelineIcon className="p-3" variant="ghost" color="">
                      <FontAwesomeIcon icon={faGraduationCap} className="h-5 w-5 text-[#b02027]" />
                    </TimelineIcon>
                    <div className="flex flex-col gap-1">
                      <Typography variant="h6" color="blue-gray">
                        <del>Scholarships are hard to apply</del>
                      </Typography>
                      <Typography variant="h5" color="" className="font-normal">
                        Apply scholarships easily from your phone through getscholify
                      </Typography>
                    </div>
                  </TimelineHeader>
                </TimelineItem>
                <TimelineItem className="h-32">
                  <TimelineConnector className="!w-[78px]" />
                  <TimelineHeader className="relative rounded-xl border border-blue-gray-50 bg-white py-3 pl-4 pr-8 shadow-lg shadow-blue-gray-900/5">
                    <TimelineIcon className="p-3" variant="ghost" color="">
                      <FontAwesomeIcon icon={faAward} className="h-5 w-5 text-[#b02027]" />
                    </TimelineIcon>
                    <div className="flex flex-col gap-1">
                      <Typography variant="h6" color="blue-gray">
                       <del> Scholarship are granted to selected students</del>

                      </Typography>
                      <Typography variant="h5" color="gray" className="font-normal">
                        Only the meritorious or financially very poor students get scholarships
                      </Typography>
                    </div>
                  </TimelineHeader>
                </TimelineItem>
                <TimelineItem className="h-32">
                  <TimelineHeader className="relative rounded-xl border border-blue-gray-50 bg-white py-3 pl-4 pr-8 shadow-lg shadow-blue-gray-900/5">
                    <TimelineIcon className="p-3" variant="ghost" color="">
                      <FontAwesomeIcon icon={faBook} className="h-5 w-5 text-[#b02027]" />
                    </TimelineIcon>
                    <div className="flex flex-col gap-1">
                      <Typography variant="h6" color="blue-gray">
                        <del>Scholarship Limited to few courses</del>
                      </Typography>
                      <Typography variant="h5" color="gray" className="font-normal">
                        Scholarship for more than 200 courses
                      </Typography>
                    </div>
                  </TimelineHeader>
                </TimelineItem>


              </Timeline>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
