import React from 'react'
import { useState } from 'react';
const Contriform = () => {
    const [formData, setFormData] = useState({
        contributeAs: 'individual',
        amount: '',
        fullName: '',
        phone: '',
        email: '',
        pan: '',
        address: '',
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://getscholify-backend.onrender.com/api/contribute', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                alert('Contribution added successfully');
            } else {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to add contribution');
            }
        } catch (error) {
            alert(error.message);
        }
    };

    return (
        <div className="w-10/12 mx-auto p-8 shadow-md rounded-md">
            <form className="space-y-6" onSubmit={handleSubmit}>
                <div>
                    <p className="font-semibold pb-4 text-lg">Contribution Request Form</p>
                    <label htmlFor="contributeAs" className="block text-sm font-medium text-gray-700">Contribute As</label>
                    <select id="contributeAs" name="contributeAs" className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" onChange={handleChange} value={formData.contributeAs}>
                        <option value="individual">Individual</option>
                        <option value="trusts">Trusts</option>
                        <option value="corporate">Corporate</option>
                        <option value="college/universities">College/Universities</option>
                    </select>
                </div>
                <div className='grid grid-cols-2 gap-5'>
                    <div>
                        <label htmlFor="amount" className="block text-sm font-medium text-gray-700">Amount</label>
                        <input type="number" name="amount" id="amount" autoComplete="amount" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" onChange={handleChange} value={formData.amount} />
                    </div>
                    <div>
                        <label htmlFor="fullName" className="block text-sm font-medium text-gray-700">Full Name</label>
                        <input type="text" name="fullName" id="fullName" autoComplete="fullname" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" onChange={handleChange} value={formData.fullName} />
                    </div>
                    <div>
                        <label htmlFor="phone" className="block text-sm font-medium text-gray-700">Phone Number</label>
                        <input type="tel" name="phone" id="phone" autoComplete="tel" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" onChange={handleChange} value={formData.phone} />
                    </div>
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email Address</label>
                        <input type="email" name="email" id="email" autoComplete="email" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" onChange={handleChange} value={formData.email} />
                    </div>
                    <div>
                        <label htmlFor="pan" className="block text-sm font-medium text-gray-700">PAN Number</label>
                        <input type="text" name="pan" id="pan" autoComplete="pan" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" onChange={handleChange} value={formData.pan} />
                    </div>
                    <div>
                        <label htmlFor="address" className="block text-sm font-medium text-gray-700">Address Details</label>
                        <textarea id="address" name="address" rows={3} className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" defaultValue={formData.address} onChange={handleChange}></textarea>
                    </div>
                </div>
                <div>
                    <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#b02027] ">
                        Contribute
                    </button>
                </div>
            </form>
        </div>



    )
}

export default Contriform