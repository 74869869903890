

import React from "react";
import { Card, CardBody, Typography, Avatar } from "@material-tailwind/react";



export function Advantage() {
    return (
        <section className="lg:py-28 py-10 px-8 bg-gray-100/50">
            <div className="container mx-auto mb-10 text-center lg:mb-20">
                <Typography
                    color="blue-gray"
                    className="mb-4 !text-2xl font-bold lg:!text-4xl"
                >
                    What's In It For You
                </Typography>
                <Typography
                    variant="lead"
                    className="mx-auto !text-gray-500"
                >
                
                We offer various partnership options to suit your specific needs and goals. Contact us today to discuss how you can contribute to shaping the future of education in India!
                  </Typography>
            </div>
            <div className="mb-8 container mx-auto grid lg:gap-x-8 gap-y-8 grid-cols-1 lg:grid-cols-3">
                <Card
                    className="col-span-1 bg-white overflow-hidden"
                    shadow={false}

                >
                    <CardBody className="text-center">
                        <Typography
                            variant="h4"
                            color="blue-gray"
                            className="mb-2 font-medium"
                        >
                            Trusts & Foundations
                        </Typography>
                        <Typography className="text-center mb-0 max-w-xs mx-auto text-base font-normal leading-7 !text-gray-500">
                            Expand your reach and ensure your scholarships find the most deserving beneficiaries.

                        </Typography>
                        <img
                            src="https://utfs.io/f/733faa6c-701b-486f-8729-9d5dd29a0d34-iwzb3c.png"
                            alt="iphone"
                            className="w-full xl:h-[370px] lg:h-[360px] lg:translate-y-8 translate-y-7 object-contain object-bottom"
                        />
                    </CardBody>
                </Card>
                <Card
                    className="col-span-2 bg-white overflow-hidden"
                    shadow={false}
                >
                    <CardBody className="text-center">
                        <Typography
                            variant="h4"
                            color="blue-gray"
                            className="mb-2 font-medium"
                        >
                            Companies
                        </Typography>
                        <Typography className="text-center mb-0 max-w-xs mx-auto text-base font-normal leading-7 !text-gray-500">
                            Fulfill your CSR goals by supporting underprivileged students and build a loyal customer base by nurturing future talent.

                        </Typography>
                        <img
                            src={"https://sviet.ac.in/_next/static/media/peace.91049f48.png"}
                            alt="laptop"
                            className="w-full lg:h-[380px] md:h-[300px] h-[220px] lg:translate-y-16 translate-y-10 object-contain object-center"
                        />
                    </CardBody>
                </Card>
            </div>
            <div className="container mx-auto grid lg:gap-x-8 gap-y-8 grid-cols-1 lg:grid-cols-3">
                <Card
                    className="col-span-2 bg-white overflow-hidden"
                    shadow={false}
                >
                    <CardBody className="text-center">
                        <Typography
                            variant="h4"
                            color="blue-gray"
                            className="mb-2 font-medium"
                        >
                            Colleges & Universities
                        </Typography>
                        <Typography className="text-center max-w-sm mx-auto text-base font-normal leading-7 !text-gray-500">
                            Showcase your scholarships to a wider pool of qualified students and attract top talent to your institution.
                        </Typography>
                        <img
                            src="https://utfs.io/f/dee73b80-003c-4f10-8d22-2462c4dc5012-1dp.png"
                            alt="laptop"
                            className="w-full xl:h-[500px] lg:h-[380px] md:h-[300px] h-[180px] lg:translate-y-6 translate-y-10 object-contain object-center"
                        />
                    </CardBody>
                </Card>
                <Card className="col-span-1 bg-white" shadow={false}>
                    <CardBody className="text-center">
                        <Typography
                            variant="h4"
                            color="blue-gray"
                            className="mb-2 font-medium"
                        >
                            Individuals
                        </Typography>
                        <Typography className="text-center max-w-xs mx-auto text-base font-normal leading-7 !text-gray-500">
                            Contribute directly to a student's educational journey and make a lasting impact on their lives.
                        </Typography>

                        <div className="flex justify-between items-end mt-[144px]">
                            <div className="flex gap-2">

                                <Typography
                                    as="a"
                                    href="#"
                                    color="blue-gray"
                                    className="mr-4 text-xs font-bold cursor-pointer"
                                >
                                </Typography>
                            </div>
                            <Typography
                                as="a"
                                href="#"
                                color="blue-gray"
                                className="text-xs font-bold cursor-pointer"
                            >

                            </Typography>
                            <img
                                src={"https://utfs.io/f/431d747d-c5c1-4261-97be-ee3bcdfaacd6-1dm.png"}
                                alt="logo"
                            />
                        </div>
                    </CardBody>
                </Card>
            </div>
        </section>
    );
}
export default Advantage;
