// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth"
import { getDatabase } from "firebase/database";
const firebaseConfig = {
    apiKey: "AIzaSyDBEhoVYfFYPx1zpexapvcDvCmfUf37Q7Q",
    authDomain: "getscholify-958e5.firebaseapp.com",
    projectId: "getscholify-958e5",
    storageBucket: "getscholify-958e5.appspot.com",
    messagingSenderId: "901787841582",
    appId: "1:901787841582:web:4c9655dd418b74408b9bbf",
    measurementId: "G-0VPL1WQR6W"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
const analytics = getAnalytics(app);
const db = getDatabase(app);
export {app, auth, db}