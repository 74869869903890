import React from 'react';

const RefundPolicy = () => {
  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <h1 className="text-3xl font-bold mb-6">Refund Policy</h1>
      <p className="text-lg mb-4">
        Welcome to Getscholify! This Refund Policy outlines the terms and conditions for requesting refunds. Please review the policy carefully.
      </p>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">1. Eligibility for Refunds</h2>
        <p className="text-base mb-2">
          Refunds are granted under the following conditions:
        </p>
        <ul className="list-disc pl-6 text-base mb-2">
          <li>Applicants have withdrawn their application before the submission deadline.</li>
          <li>Incorrect payment was made, and the request for a refund is submitted within 30 days of the transaction.</li>
          <li>Other conditions as outlined in the specific scholarship program guidelines.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">2. Refund Request Process</h2>
        <p className="text-base mb-2">
          To request a refund, please follow these steps:
        </p>
        <ol className="list-decimal pl-6 text-base mb-2">
          <li>Complete the refund request form available on our website.</li>
          <li>Provide supporting documents as required (e.g., proof of payment, reason for refund).</li>
          <li>Submit the form and documents to www.getsholfy.com.</li>
          <li>Our team will review your request and notify you of the outcome within 7 business days.</li>
        </ol>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">3. Refund Processing Time</h2>
        <p className="text-base mb-2">
          Refunds will be processed within 7 business days after approval. The time it takes for the refund to reflect in your account may vary depending on your bank or payment provider.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">4. Non-Refundable Fees</h2>
        <p className="text-base mb-2">
          The following fees are non-refundable:
        </p>
        <ul className="list-disc pl-6 text-base mb-2">
          <li>Application processing fees.</li>
          <li>Late fees for applications submitted after the deadline.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">5. Contact Us</h2>
        <p className="text-base mb-2">
          For any questions or concerns regarding refunds, please contact us at contact@getscholify.com. Our team will be happy to assist you.
        </p>
      </section>

      <p className="text-base">
        By applying for a scholarship through our portal, you acknowledge that you have read and understood this Refund Policy and agree to the terms outlined herein.
      </p>
    </div>
  );
};

export default RefundPolicy;
