import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';


const Vision = () => {
    const reasons = [
        {
            imageSrc: 'https://utfs.io/f/83a3fe15-9e9d-4d09-9658-4875a006f730-rhoi1k.jpg',
            title: 'Financial Assistance',
            description: 'Our scholarship programs are designed to provide financial assistance to deserving students, enabling them to pursue their academic dreams without financial burden.'
        },
        {
            imageSrc: "https://utfs.io/f/08abd4ea-8f3f-43b0-b1ff-43e242ee92cf-4c428l.jpg",
            title: 'Academic Excellence',
            description: 'Recognizing academic excellence, our scholarships reward students who have demonstrated outstanding performance in their studies.'
        },
        {
            imageSrc: "https://utfs.io/f/67d27ca2-fa8b-45cc-a64d-950125bf5d5e-ot0ax1.avif",
            title: 'Community Engagement',
            description: 'We value community engagement and leadership. Our scholarships support students who actively contribute to their communities and demonstrate leadership skills.'
        },
    ];

    const settings = {
        infinite: true,
        dots: true,
        autoplay: true,
        arrows: false,
        speed: 300,
        slidesToShow: 3, // Show 3 slides on desktop
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2, // Show 2 slides on tablet
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1, // Show 1 slide on phone
                }
            }
        ]
    };

    return (
        <div className='text-white'>
            <div className="w-full h-full bg-[#f9f9f9]">
                <div className="w-10/12 mx-auto py-12">
                    <p className='text-3xl font-medium pb-3 text-black'>
                        Our Vision & <span className='text-[#AF0305]'>Values</span>
                    </p>
                    <p className='text-xl pl-2 border-l-4 border-[#b02027]'>
                        The goal of education is the advancement of knowledge and the dissemination of truth
                    </p>


                    <Slider {...settings}>
                        {reasons.map((reason, index) => (
                            <article key={index} className="mx-auto mt-8 mb-12 w-full shadow:lg h-[50vh] transform duration-500 hover:-translate-y-2 cursor-pointer group " >
                                <div className='h-[50vh] bg-cover bg-center mx-auto w-[95%] rounded-lg ' style={{ backgroundImage: `url(${reason.imageSrc})`, backgroundPosition: " center", backgroundSize: "cover" }}>
                                    <div className="h-[50vh] py-4 bg-black bg-opacity-20  px-6 rounded-lg  shadow:lg flex flex-wrap flex-col justify-end pb-12 pt-48 hover:bg-opacity-75 transform duration-300 ">
                                        <h1 className="text-white text-xl mb-5 transform translate-y-20 group-hover:translate-y-0 duration-300 border-l-4 border-[#b02027] px-4">
                                            {reason.title}
                                        </h1>

                                        <p className="opacity-0 text-white text-md group-hover:opacity-80 transform duration-500">
                                            {reason.description}
                                        </p>
                                    </div>
                                </div>
                            </article>

                        ))}
                    </Slider>




                </div>
            </div>
        </div>
    );
}

export default Vision;
