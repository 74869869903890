import React from 'react'
import Whyusheader from '../Components/WhyusComponents/Whyusheader'
import Secondsection from '../Components/WhyusComponents/Secondsection'
import Best from '../Components/WhyusComponents/Best'
import Usps from '../Components/WhyusComponents/Usps'
import Edge from '../Components/WhyusComponents/Edge'
import Students from '../Components/WhyusComponents/Students'
import Calltoaction from '../Components/WhyusComponents/Calltoaction'
import Unlock from '../Components/WhyusComponents/Unlock'
import Secondcomp from '../Components/WhyusComponents/Secondcomp'
const Whyus = () => {
    return (
        <>
            <Whyusheader />
            <Secondcomp/>
            <Secondsection />
            <Edge />
            <Usps />
            {/* <Students /> */}
            <Unlock/>

        </>
    )
}

export default Whyus