import React from 'react'
import AboutHeader from "../Components/About/AboutHeader"


const Courses = () => {
    // const { id } = useParams()
    // const program = programsData.find(program => program.id === parseInt(id));
    // console.log(program)
    return (
        <div>
            <AboutHeader />
            
        </div>
    )
}

export default Courses