import React from 'react'
import Header from '../Components/Home/Header'
import Courses from '../Components/Home/Courses'
import Methodology from '../Components/Home/Methodology'
import Dreams from '../Components/Home/Dreams'
import Guidance from '../Components/Home/Guidance'
import OurAim from '../Components/Home/OurAim'
import Success from '../Components/Home/Success'
import HomeForm from '../Components/Home/HomeForm'
import TestimonialSectionCarousel from '../Components/Home/Testimonials'
import Secondcomp from '../Components/Home/Secondcomp'

const Home = () => {
    return (
        <div>
            <Header />
            <Secondcomp/>
            <OurAim />
            {/* <HomeForm/> */}
            <Courses />
            <Dreams />
            <Guidance />
            <Success />
            <Methodology />
            <TestimonialSectionCarousel/>
        </div>
    )
}

export default Home