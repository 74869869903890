import React from 'react'
import Home from './Components/../Views/Home'
import RouterOutlet from './RouterOutlet'
import Navbar from './Layout/Navbar'
import Footer from './Layout/Footer'

const App = () => {
  return (
    <div>
      <Navbar/>
      <RouterOutlet />
     <a href='https://wa.me/9204531483'>
     <i className='fa fa-whatsapp text-3xl rounded-full p-2 bg-[#b02027] text-white z-50 end-4 shadow-md bottom-4 fixed '></i>
     </a> <Footer/>
    </div>
  )
}

export default App