import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import headerimage from '../../Assets/pikaso_texttoimage_header-image-for-my-indias-best-scholarsihp-portal.jpeg'
import masterData from '../../data';
import namesData from '../../namesData'
import scholarships from '../../scholarshipData';
const Header = () => {
    const history = useNavigate();
    const [dropdown1Value, setDropdown1Value] = useState('');
    const [dropdown2Value, setDropdown2Value] = useState('');

    const [filters, setFilters] = useState({
        type: '',
        organization: '',
        amount: ''
    });
    const [scholarshipTypes, setScholarshipTypes] = useState([]);
    const [scholarshipOrganizations, setScholarshipOrganizations] = useState([]);

    useEffect(() => {
        // Extract all unique scholarship types and organizations
        const types = new Set();
        const organizations = new Set();

        scholarships.forEach(category => {
            category.scholarships.forEach(scholarship => {
                types.add(scholarship.type);
                organizations.add(scholarship.organisation);
            });
        });

        setScholarshipTypes(Array.from(types));
        setScholarshipOrganizations(Array.from(organizations));
    }, []);

    const filteredScholarships = () => {
        let filtered = [];
        scholarships.forEach(category => {
            filtered = filtered.concat(category.scholarships);
        });
        if (filters.type) {
            filtered = filtered.filter(scholarship => scholarship.type === filters.type);
        }
        if (filters.organization) {
            filtered = filtered.filter(scholarship => scholarship.organisation === filters.organization);
        }
        if (filters.amount) {
            filtered = filtered.filter(scholarship => parseFloat(scholarship.amount.split(' ')[1]) <= parseFloat(filters.amount));
        }
        return filtered;
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setDropdown1Value(e.target.value)
        setFilters({ ...filters, [name]: value });
    };

    // Function to handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        // Redirect to the /scholarship page with selected dropdown values as URL parameters
        history(`/scholarship?type=${dropdown1Value}`);
    };

    // Function to get organizations based on selected type
    const getOrganizations = (type) => {
        const selectedType = masterData.find((data) => data.type === type);
        return selectedType ? selectedType.organizations : [];
    };

    return (
        <div className="w-10/12 mx-auto py-4 h-full main-header flex flex-col justify-center">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 h-full">
                <div className="px-4 py-4 h-full">
                    <div className="h-full mx-auto flex flex-col justify-center header-written h-custom-height">
                        <p className="text-2xl lg:text-3xl font-bold text-gray-800 w-full pb-4">
                            Welcom To <span className="text-red-500">Getscholify</span> <br />
                            India's Best Scholarship Portal
                        </p>
                        <p className="text-gray-600 pb-4">
                            We provide the support to students to pursue their dreams of higher education with the
                            right course to give wings to their careers and also fulfill parents aspirations
                        </p>
                        <div className="py-2 pb-4">


                            <div className="grid grid-cols-1 md:grid-cols-3 gap-3 w-full border border-gray-300 p-3 rounded-lg">
                                <select
                                id="dropdown1"
                                    className="mt-1 block w-full py-2 px-3 my-4 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    name="type"
                                    value={filters.type}
                                    onChange={handleFilterChange}
                                >
                                    <option value="">Select Scholarship Type</option>
                                    {scholarshipTypes.map((type, index) => (
                                        <option key={index} value={type}>{type}</option>
                                    ))}
                                </select>
                                <div className="dropdown">
                                    <select
                                        id="dropdown2"
                                        value={dropdown2Value}
                                        onChange={(e) => setDropdown2Value(e.target.value)}
                                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                    >
                                        <option value="">Select Course</option>
                                        {namesData.map((organization, index) => (
                                            <option key={index} value={organization.category}>{organization.category}</option>
                                        ))}
                                    </select>
                                </div>
                                <button
                                    className="bg-[#b02027] text-white px-4 py-2 rounded-md w-full"
                                    onClick={handleSubmit}
                                >
                                    Search
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center">
                    <img src={headerimage}
                        alt="" className="w-full h-auto lg:h-full lg:w-auto" style={{ borderRadius: "10% 0% 10% 0%" }} />
                </div>
            </div>
        </div>
    );
}

export default Header;
