import React from 'react'
import JoinHeader from '../Components/JoinOurTeam/TeamHeader'
import Form from '../Components/JoinOurTeam/Form'
import Calltoaction from '../Components/WhyusComponents/Calltoaction'

const JoinOurTeam = () => {
    return (
        <div>
            <JoinHeader />
            <Form />
            <Calltoaction />
        </div>
    )
}

export default JoinOurTeam