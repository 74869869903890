import React from 'react'
import WelcomeHeader from '../Components/Students/WelcomeHeader'
import DashBoard from '../Components/Students/DashBoard'
import { useAuth } from '../context/authContext'
import Login from '../auth/Login'
import ScholarshipForm from '../Components/Students/ScholarshipForm'
import { doSignOut } from '../firebase/auth'
const Student = () => {

  const { userLoggedIn } = useAuth()
  const {currentUser} = useAuth()
  console.log(currentUser)
  return (
    <div>
      {
        userLoggedIn ? (
          <div>
          
            <DashBoard  mail={currentUser.mail} userId={currentUser.uid} firstName={currentUser.displayName ? currentUser.displayName : currentUser.email} email={currentUser.email} image={currentUser.photoURL} />
            <div className="hidden"><ScholarshipForm userId={currentUser.uid} mail={currentUser.email}/></div>
          </div>
        ) :
          <div>
            <Login />
          </div>
      }
    </div>
  )
}

export default Student