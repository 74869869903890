
import React from 'react'
import { Link } from 'react-router-dom'
import logoWhite from '../Assets/Brand/LogoWhite.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faYoutube, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone, faLocationDot } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
    return (
        <footer className="bg-[#011f3b] text-white">

            <div className="max-w-6xl m-auto flex flex-wrap gap-y-8">
                {/* Logo and Address section */}
                <div className="w-full md:w-1/2 lg:w-1/4 mb-8 md:mb-0 pr-10 flex items-center">
                    <div className="logo mt-4">
                        <img src={logoWhite} alt="" className='pb-5  text-sm mb-5' />
                    </div>



                </div>
                {/* Admissions section */}
                <div className="w-full md:w-1/2 lg:w-1/4  mb-8 md:mt-2 p-6 ">
                    <div className="text-[#b02027] text-xl font-bold mb-4">Getting Started</div>
                    <ul className="leading-loose text-md     font-light flex flex-col gap-2">
                       
                        <Link to="/" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
                            Home
                        </Link>
                        <Link to="/about-us" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
                            About Us
                        </Link>
                        <Link to="/contact" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
                            Contact
                        </Link>
                        <Link to="/terms-conditions" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
                            Terms and Conditions
                        </Link>
                        <Link to="/privacy-policy" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
                            Privacy Policy
                        </Link>
                        <Link to="/refund-policy" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
                            Refund Policy
                        </Link>

                    </ul>
                </div>
                {/* Academics section */}
                <div className="w-full md:w-1/2 lg:w-1/4 mb-8 p-6">
                    <div className="text-[#b02027] text-xl font-bold mb-4">Core Concepts</div>
                    <ul className="leading-loose text-md flex flex-col gap-2 font-light">
                        <Link to="/scholarship">Scholarships</Link>
                        <Link to="/student">Student Dashboard</Link>
                        <Link to="/">Courses</Link>
                        {/* <Link to="/scholarship">Scholarships</Link> */}
                    </ul>
                </div>
                {/* Resources section */}
                <div className="w-full md:w-1/2 lg:w-1/4 mb-8 p-6">
                    <div className="text-[#b02027] text-xl font-bold mb-4">Contact Us</div>
                    <ul className="leading-loose text-md gap-y-4 font-light">
                        <li><a href=""><FontAwesomeIcon className='w-6' icon={faPhone} /><span className='ml-5'>9204531483</span></a></li>
                        <li><a href=""><FontAwesomeIcon className='w-6' icon={faEnvelope} /> <span className='ml-5'>getscholify@gmail.com</span></a></li>
                        <li><a href=""><FontAwesomeIcon className='w-6' icon={faLocationDot} /><span className='ml-5'>Greater Noida, India</span></a></li>
                        <li><a href=""><FontAwesomeIcon className='w-6' icon={faInstagram} /> <span className='ml-5'>getscholify</span></a></li>

                    </ul>
                </div>
            </div>

            {/* Copyright Bar */}
            <div className="pt-2">
                <div className="flex pb-5 px-3 m-auto pt-5 
            border-t border-white text-white text-sm 
            flex-col md:flex-row max-w-6xl">
                    <div className="mt-2">
                        © Copyright 2022-year. All Rights Reserved.
                    </div>
                    <div className="md:flex-auto md:flex-row-reverse mt-2 flex-row flex">
                        <a href="https://www.facebook.com/Getscholify/" className="w-6 mx-1">
                            <FontAwesomeIcon icon={faFacebookF} />
                        </a>

                        <a href="https://www.linkedin.com/company/getscholify/about/" className="w-6 mx-1">
                            <FontAwesomeIcon icon={faLinkedin} />
                        </a>
                        <a href="https://www.instagram.com/getscholify/" className="w-6 mx-1">
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer



