import React from 'react';
import back from '../../Components/assets/home/backdrop.jpg'


const serviceData = [
    {

        title: '1. 85% Application Acceptance Rate',
        description:
            'At GetScholify, we provide the students a wide variety of scholarships offered by many reputed college nationwide, giving students, a wide variety of options to choose from.',
    },
    {

        title: '2. Many Scholarships at Single Platform',
        description:
            'Choose from a variety of scholarships of different eligibility and criteria offered by government and colleges themselves. Register at GetScholify to know more about them',
    },
    {

        title: '3. Upto 100% Scholarship Available',
        description:
            'Get a chance to study at the college of your choice and the course program of your liking with GetScholify. Our procedures are transparent, authentic, and trustworthy and also trackable',
    },
    {

        title: '4. Upto 100% Scholarship Available',
        description:
            'Get a chance to study at the college of your choice and the course program of your liking with GetScholify. Our procedures are transparent, authentic, and trustworthy and also trackable',
    },
    {

        title: '5. Upto 100% Scholarship Available',
        description:
            'Get a chance to study at the college of your choice and the course program of your liking with GetScholify. Our procedures are transparent, authentic, and trustworthy and also trackable',
    },
];

const Guidance = () => {
    return (
        <section className="wow fadeIn animated py-8  bg-[#b02027] text-white" style={{ visibility: 'visible', animationName: 'fadeIn' }}>
            <div className="w-10/12 mx-auto py-4">
                <div className="mb-4 py-4 d-block">
                    <p className="text-xl md:text-4xl font-semibold border-l-4 px-4 border-white mb-4">
                        Our Stats So Far
                    </p>
                    <p>
                        To emerge as a bridge between colleges &amp; corporate in the fields of engineering, technology, and
                        management in serving the industry and the nation by empowering students with a high degree of
                        technical, managerial, and practical competence.
                    </p>
                </div>
                <div className="flex flex-wrap md:flex-nowrap mt-4 gap-4">
                    {/* counter */}
                    <div className="w-full md:w-1/3 flex flex-col gap-2 border-r-2 border-white col-sm-6 bottom-margin text-center counter-section wow fadeInUp sm-margin-bottom-ten animated" data-wow-duration="300ms" style={{ visibility: 'visible', animationDuration: '300ms', animationName: 'fadeInUp' }}>
                        <i className="fa-brands fa-google-scholar text-2xl md:text-5xl" />
                        <span id="anim-number-pizza" className="counter-number" />
                        <span className="timer text-white counter alt-font appear text-xl md:text-3xl font-bold" data-to={980} data-speed={7000}>1500</span>
                        <p className="counter-title text-xl font-semibold">Scholarships Offered</p>
                    </div>
                    {/* end counter */}
                    {/* counter */}
                    <div className="w-full md:w-1/3 flex flex-col gap-2 border-r-2 border-white col-sm-6 bottom-margin text-center counter-section wow fadeInUp sm-margin-bottom-ten animated" data-wow-duration="600ms" style={{ visibility: 'visible', animationDuration: '600ms', animationName: 'fadeInUp' }}>
                        <i className="fa fa-book text-2xl md:text-5xl" />
                        <span className="timer text-white counter alt-font appear text-xl md:text-3xl font-bold" data-to={980} data-speed={7000}>50</span>
                        <span className="counter-title text-xl font-semibold">Courses Available</span>
                    </div>
                    {/* end counter */}
                    {/* counter */}
                    <div className="w-full md:w-1/3 flex flex-col gap-2 col-sm-6 bottom-margin-small text-center counter-section wow fadeInUp xs-margin-bottom-ten animated" data-wow-duration="900ms" style={{ visibility: 'visible', animationDuration: '900ms', animationName: 'fadeInUp' }}>
                        <i className="fa fa-user text-2xl md:text-5xl" />
                        <span className="timer text-white counter alt-font appear text-xl md:text-3xl font-bold" data-to={810} data-speed={7000}>25</span>
                        <span className="counter-title text-xl font-semibold">Contributors' Support</span>
                    </div>
                    {/* end counter */}
                    {/* counter */}
                    {/* end counter */}
                </div>
            </div>
        </section>

    );
};

export default Guidance;
