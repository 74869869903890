import React from 'react'
import img1 from '../../Assets/iconss/value1.png'
import img2 from '../../Assets/iconss/value2.png'
import img3 from '../../Assets/iconss/value3.png'
const serviceData = [
    {
        icon: img1 ,
        title: ' Accountability',
        // description:
        //     'Our scholarship programs aim to support and nurture talent, providing financial assistance to those who exhibit academic excellence, leadership potential, and a commitment to making a positive impact in their respective fields.',
        // link: '/',
    },
    {
        icon: img2 ,
        title: 'Integrity',
        // description:
        //     ' Our institution proudly provides a broad spectrum of courses designed to cater to the varied interests and career aspirations of our students. From foundational courses that build a strong academic base to specialized programs that delve deep into specific fields.',
        // link: '/',
    },
    {
        icon: img3 ,
        title: 'Transparency',
        // description:
        //     'Recognizing the importance of contributors is essential in fostering a culture of collaboration and acknowledging the myriad ways in which individuals can positively influence and contribute to the betterment of society as a whole.',
        // link: '/',
    },
];

const Advantages = () => {
    return (
        <section className="bg-[#f8f8f8] py-8">
            <div className="container-fluid">
                <div className="w-10/12 mx-auto py-8">
                    <div className="flex flex-wrap md:flex-nowrap">
                        <div className="w-full">
                            <div className="horizontal-timeline w-full">
                                <ul className="flex flex-wrap md:flex-nowrap gap-4 list-style-none">
                                    <li className="w-full md:w-1/3 border-white">
                                        <div className="flex flex-wrap gap-3 items-center justify-normal md:justify-center">
                                            <i className="fa fa-graduation-cap border] border-2 rounded-full bg-[#b0202710] text-[#b02027] p-3 text-lg md:text-2xl" />
                                            {/* <div class="event-date badge bg-danger">1.</div> */}
                                            <h5 className="text-xl md:text-xl text-[#b02027] font-semibold">Accountability</h5>
                                        </div>
                                    </li>
                                    <li className="w-full md:w-1/3 border-white">
                                        <div className="flex flex-wrap gap-3 items-center justify-normal md:justify-center">
                                            <i className="fas fa-money-bill rounded-full bg-[#b0202710] text-[#b02027] p-3 text-lg md:text-2xl" />
                                            <h5 className=" text-xl md:text-xl text-[#b02027] font-semibold">Integrity</h5>
                                        </div>
                                    </li>
                                    <li className="w-full md:w-1/3 border-white">
                                        <div className="flex flex-wrap gap-3 items-center justify-normal md:justify-center">
                                            <i className="fa fa-book rounded-full bg-[#b0202710] text-[#b02027] p-3 text-lg md:text-2xl" />
                                            <h5 className=" text-xl md:text-xl text-[#b02027] font-semibold">Transparency</h5>
                                        </div>
                                    </li>
                                   
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};


export default Advantages