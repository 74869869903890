import React, { useState } from 'react';

const LetterUpload = ({id}) => {
  const [formData, setFormData] = useState({
    userId: id,
    file: null,
  });

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: files ? files[0] : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('userId', formData.userId);
      formDataToSend.append('file', formData.file);

      const response = await fetch('https://getscholify-backend.onrender.com/api/letters/upload', {
        method: 'POST',
        body: formDataToSend,
      });

      if (response.ok) {
        alert('File uploaded successfully');
      } else {
        alert('Failed to upload file');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to upload file');
    }
  };

  return (
    <div className="mx-auto p-6 bg-white rounded-lg">
      <h2 className="text-2xl font-semibold mb-4">Upload a Letter</h2>
      <form className="space-y-4" onSubmit={handleSubmit}>
        <div>
          <label htmlFor="userId" className="block text-gray-700 font-semibold">
            User ID and Name
          </label>
          <label htmlFor="">{id}</label>
        </div>
        <div>
          <label htmlFor="file" className="block text-gray-700 font-semibold">
            Upload File
          </label>
          <div className="flex items-center justify-between border rounded-md px-4 py-2 mt-2 focus-within:border-blue-500">
            <input
              type="file"
              id="file"
              name="file"
              onChange={handleInputChange}
              className="hidden"
            />
            <label
              htmlFor="file"
              className="cursor-pointer text-blue-500 hover:text-blue-700 focus:outline-none"
            >
              Choose File
            </label>
            <span className="text-gray-500">{formData.file ? formData.file.name : 'No file chosen'}</span>
          </div>
        </div>
        <button
          type="submit"
          className="bg-blue-500 text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
        >
          Upload Letter
        </button>
      </form>
    </div>
  );
};

export default LetterUpload;
