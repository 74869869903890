import React from 'react'
import img from './pikaso_texttoimage_an-header-image-for-my-scholarship-portal-for-the-.jpeg'
const Whyusheader = () => {
    return (
        <div className="w-10/12 mx-auto py-12 h-full main-header flex flex-col justify-center">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 h-full">
                <div className="px-4 py-4 h-full">
                    <div className="h-full mx-auto flex flex-col justify-center header-written h-custom-height">
                        <p className="text-2xl lg:text-3xl font-bold text-gray-800 w-full pb-4">
                            Why <span className="text-red-500">Us</span> <br />
                            India's Best Scholarship Portal
                        </p>
                        <p className=" pb-4 text-md font-semibold">
                            We provide the support to students to pursue their dreams of higher education with the right course to give wings to their careers and also fulfill parents aspirations

                        </p>
                        <div className="py-2 pb-4">

                            {/* Transforming Lives and Communities Through Our Scholarships */}
                        </div>
                    </div>
                </div>
                <div className="flex justify-center">
                    <img src={img} alt="" srcset="" className=' shadow-md border-b-8 border-[#b02027]' style={{ borderRadius: "10% 0 10% 0" }} />
                </div>
            </div>
        </div>
    )
}

export default Whyusheader