import React from 'react'

const WelcomeHeader = ({firstName, email}) => {
  return (
    <div className=''>
        <div className="pb-6">
            <p className="text-lg font-bold">
               Hello! {firstName} 
            </p>
            <p className="text-sm lg:block hidden">
               {email}
            </p>
        </div>
    </div>
  )
}

export default WelcomeHeader