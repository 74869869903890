const masterData = [
    {
        type:"College/Univeristy/Getscholify Scholarships",
        organizations : [
            {
                "name": "GetScholify",
            },
            
            {
                "name": "Swami Vivekanand Institute of Engineering and Technology",
                "location": "Chandigarh",
                "rating": "4.3/5",
                "image": "https://sviet.ac.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FLogo.8bdb37ea.webp&w=640&q=75"
            },
           
           
            {
                "name": "Manipal Academy of Higher Education",
                "location": "Manipal, Karnataka",
                "rating": "4.3/5",
                "image": "https://utfs.io/f/c573bf67-cac1-4f14-bebe-d332770fd78c-dtftgs.webp"
            },
            {
                "name": "Guru Nanak Dev University",
                "location": "Amritsar, Punjab",
                "rating": "4.0/5",
                "image": "https://utfs.io/f/8a6b6120-60ae-417f-b861-f57c7e59c14d-1w3p4.jpg"
            },
            {
                "name": "VIT University",
                "location": "Vellore, Tamil Nadu",
                "rating": "4.2/5",
                "image": "https://utfs.io/f/07cb60d4-7f28-497c-9cd6-76a44afa645e-s0qi9g.jpeg"
            },
            {
                "name": "BITS Pilani",
                "location": "Pilani, Rajasthan",
                "rating": "4.4/5",
                "image": " https://utfs.io/f/ff365991-7fd7-4200-9c81-0e35eaf73074-17pc6.jpeg"
            },
            {
                "name": "SRM Institute of Science and Technology",
                "location": "Chennai, Tamil Nadu",
                "rating": "4.1/5",
                "image": "https://utfs.io/f/bdb219cb-2f59-4435-9f95-3351856fd8ef-2g32.png"
            },
            {
                "name": "Panjab University",
                "location": "Chandigarh, Punjab",
                "rating": "4.2/5",
                "image": "https://utfs.io/f/60995364-c82a-43cf-af7d-45802ac66c5a-imit5q.jpeg"
            },
            {
                "name": "Lovely Professional University",
                "location": "Jalandhar, Punjab",
                "rating": "4.5/5",
                "image": "https://utfs.io/f/b2aa9ea3-2e68-4dc3-983c-ce937caf787c-2aup.jpg"
            },
            {
                "name": "Parul University",
                "location": "Vadodara, Gujarat",
                "rating": "4.6/5",
                "image": "https://utfs.io/f/504f7531-d526-44c9-ad8b-7912ef889c17-1rdblk.jpg"
            },
            {
                "name": "Amity University",
                "location": "Noida, Uttar Pradesh",
                "rating": "4.2/5",
                "image": "https://utfs.io/f/ce5cdaac-63f1-494d-b676-591cb5227371-11qgv6.avif"
            },
            {
                "name": "Christ University",
                "location": "Bangalore, Karnataka",
                "rating": "4.4/5",
                "image": "https://utfs.io/f/c5981981-3fea-4de7-8f5d-2dc32c3cea9f-midu0z.png"
            },
            {
                "name": "Ashoka University",
                "location": "Sonipat, Haryana",
                "rating": "4.5/5",
                "image": "https://utfs.io/f/e82e405e-f70c-4ac5-b514-a342a7a09748-2qjcpr.jpeg"
            },
            {
                "name": "Shiv Nadar University",
                "location": "Greater Noida, Uttar Pradesh",
                "rating": "4.4/5",
                "image": "https://utfs.io/f/0770356f-859f-4e8a-9538-904efd1af5e4-e6j7ac.jpg"
            },
            {
                "name": "O.P. Jindal Global University",
                "location": "Sonipat, Haryana",
                "rating": "4.3/5",
                "image": "https://utfs.io/f/621dc2a7-7bd7-4f7e-8574-3ee52496370e-5i23pb.jpg"
            },
            {
                "name": "Manipal Institute of Technology",
                "location": "Manipal, Karnataka",
                "rating": "4.2/5",
                "image": "https://utfs.io/f/f2789012-ef11-4894-80b6-6cc13c9c8cfe-1mwo.jpeg"
            },
            {
                "name": "Narsee Monjee Institute of Management Studies (NMIMS)",
                "location": "Mumbai, Maharashtra",
                "rating": "4.2/5",
                "image": "https://utfs.io/f/acfeab23-d33a-4865-94f0-5bfb196d9624-1qh7e8.jpg"
            },
            {
                "name": "Jain University",
                "location": "Bangalore, Karnataka",
                "rating": "4.3/5",
                "image": "https://utfs.io/f/0fb1288c-ce10-404b-8161-4a116cc0dfda-flp4lw.jpg"
            },
            {
                "name": "Kalinga Institute of Industrial Technology (KIIT)",
                "location": "Bhubaneswar, Odisha",
                "rating": "4.1/5",
                "image": "https://utfs.io/f/55b75b50-7be7-4dd1-ba63-327b124edd50-1dfyh.jpg"
            },
            {
                "name": "Assam University",
                "location": "Silchar, Assam",
                "rating": "4.0/5",
                "image": "https://utfs.io/f/ba2045fe-eae8-43b4-b73f-8286122eba22-jq57st.jpeg"
            },
            {
                "name": "Himachal Pradesh University",
                "location": "Shimla, Himachal Pradesh",
                "rating": "4.2/5",
                "image": "https://utfs.io/f/82082881-99a8-45f0-8589-988673373ef4-27vx.webp"
            },
            {
                "name": "Banasthali Vidyapith",
                "location": "Banasthali, Rajasthan",
                "rating": "4.0/5",
                "image": "https://utfs.io/f/282437cc-ba79-4e72-a3c7-c9ee08d4c180-bw0w5l.jpeg"
            },
            {
                "name": "Gujarat University",
                "location": "Ahmedabad, Gujarat",
                "rating": "4.2/5",
                "image": "https://utfs.io/f/282437cc-ba79-4e72-a3c7-c9ee08d4c180-bw0w5l.jpeg"
            },
            {
                "name": "ICFAI Foundation for Higher Education (IFHE)",
                "location": "Hyderabad, Telangana",
                "rating": "4.3/5",
                "image": "https://utfs.io/f/b2d5de7b-b3a5-4803-81df-dea6aeabb0f8-1njs1g.jpeg"
            },
            {
                "name": "Azim Premji University",
                "location": "Bangalore, Karnataka",
                "rating": "4.4/5",
                "image": "https://utfs.io/f/a9082b3d-6252-475e-8569-ebe918f3da8d-1sisd.jpg"
            },
            {
                "name": "Symbiosis Institute of Business Management",
                "location": "Pune, Maharashtra",
                "rating": "4.2/5",
                "image": "https://utfs.io/f/15f9b77b-99c1-4898-ad6f-02ad2d77d155-23zwb.png"
            }
        ]
    },
    {
        type:"Corporate CSR Scholarships",
        organizations : [
            {
                "name": "Reliance Industries Limited",
                "location": "Mumbai, Maharashtra",
                "scholarship_program": "Reliance Foundation Scholarship Program",
                "image": "https://utfs.io/f/34ce57be-c7c7-45b5-a3f9-b7b9e113cf39-921681.png"
            },
            {
                "name": "Tata Group",
                "location": "Mumbai, Maharashtra",
                "scholarship_program": "Tata Trusts Scholarship Program",
                "image": " https://utfs.io/f/fac11fb7-45c0-4ac6-9a8e-94242c33c7f6-24596.jpeg"
            },
            {
                "name": "Aditya Birla Group",
                "location": "Mumbai, Maharashtra",
                "scholarship_program": "Aditya Birla Scholarship Program",
                "image": "https://utfs.io/f/6235796b-65ef-4183-9ab6-4bd0d9c30a40-wrq3gl.jpeg"
            },
            {
                "name": "Infosys Foundation",
                "location": "Bangalore, Karnataka",
                "scholarship_program": "Infosys Foundation Scholarship",
                "image": " https://utfs.io/f/b9f3b761-095c-452a-ac78-c4b137ed4f09-1xdpq.png"
            },
            {
                "name": "Wipro Limited",
                "location": "Bangalore, Karnataka",
                "scholarship_program": "Wipro Cares Scholarship",
                "image": "https://utfs.io/f/def8e8ec-c8a1-4986-8de5-b6d26cadbc0e-1vcy3f.png"
            },
            {
                "name": "Mahindra Group",
                "location": "Mumbai, Maharashtra",
                "scholarship_program": "Mahindra All India Talent Scholarship",
                "image": "https://utfs.io/f/0e5329aa-3c8c-4ae3-96cc-e8fe95906cfe-ocwau.png "
            },
            {
                "name": "Bharti Foundation",
                "location": "New Delhi, Delhi",
                "scholarship_program": "Satya Bharti Scholarship Program",
                "image": "https://utfs.io/f/4d64691a-6c1b-4999-9047-6fa58b200d08-mzq4ic.jpg"
            },
            {
                "name": "HCL Foundation",
                "location": "Noida, Uttar Pradesh",
                "scholarship_program": "HCL Scholarship Program",
                "image": "https://utfs.io/f/1ba8e12d-1c72-488a-a2c4-ac24324539f5-27kh.png"
            },
            {
                "name": "Microsoft India",
                "location": "Hyderabad, Telangana",
                "scholarship_program": "Microsoft Scholarships",
                "image": "https://utfs.io/f/878c4c4a-b1d6-49cf-988d-1751d5088ff2-1k3mz6.jpg"
            },
            {
                "name": "Google India",
                "location": "Bangalore, Karnataka",
                "scholarship_program": "Google Scholarships",
                "image": "https://utfs.io/f/2b2c9de5-28e3-42eb-afc4-f1b218f102c8-kieqx3.png"
            }
        ]
    },
    {
        type:"Goverment Scholarships",
        organizations:[
            {
                "name":"Government of India"
            }
        ]
    }

]

export default masterData