import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <h1 className="text-3xl font-bold mb-6">Terms and Conditions</h1>
      <p className="text-lg mb-4">
        Welcome to Getscholify! By using our website, you agree to comply with and be bound by the following terms and conditions. Please review them carefully.
      </p>
      
      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">1. Eligibility</h2>
        <p className="text-base mb-2">
          To be eligible for the scholarship, applicants must meet the following criteria:
        </p>
        <ul className="list-disc pl-6 text-base mb-2">
          <li>Be a current student or prospective student at an accredited institution.</li>
          <li>Maintain a minimum GPA of 7.</li>
          <li>Submit a completed application by the deadline.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">2. Application Process</h2>
        <p className="text-base mb-2">
          The application process involves the following steps:
        </p>
        <ol className="list-decimal pl-6 text-base mb-2">
          <li>Complete the online application form.</li>
          <li>Submit required documents, including transcripts and letters of recommendation.</li>
          <li>Participate in an interview, if required.</li>
        </ol>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">3. Scholarship Award</h2>
        <p className="text-base mb-2">
          Scholarships are awarded based on the following criteria:
        </p>
        <ul className="list-disc pl-6 text-base mb-2">
          <li>Academic achievement.</li>
          <li>Financial need.</li>
          <li>Extracurricular involvement and leadership.</li>
        </ul>
        <p className="text-base mb-2">
          Award amounts and terms may vary and will be communicated to recipients upon selection.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">4. Code of Conduct</h2>
        <p className="text-base mb-2">
          Scholarship recipients are expected to adhere to the following code of conduct:
        </p>
        <ul className="list-disc pl-6 text-base mb-2">
          <li>Maintain a high standard of academic performance.</li>
          <li>Act in accordance with the scholarship’s ethical guidelines.</li>
          <li>Report any changes in status to the scholarship committee promptly.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">5. Disqualification</h2>
        <p className="text-base mb-2">
          The scholarship committee reserves the right to disqualify any applicant or recipient who:
        </p>
        <ul className="list-disc pl-6 text-base mb-2">
          <li>Fails to meet eligibility requirements.</li>
          <li>Provides false or misleading information.</li>
          <li>Violates the terms and conditions of the scholarship.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">6. Changes to Terms</h2>
        <p className="text-base mb-2">
          We reserve the right to update or change these terms and conditions at any time. We will notify users of any significant changes through our website.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">7. Contact Us</h2>
        <p className="text-base mb-2">
          If you have any questions about these terms and conditions, please contact us at [contact information].
        </p>
      </section>

      <p className="text-base">
        By using our scholarship portal, you acknowledge that you have read and understood these terms and conditions and agree to be bound by them.
      </p>
    </div>
  );
};

export default TermsAndConditions;
