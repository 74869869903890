import React from "react";
import "../../Styling/Prosper.css";
import prosper from '../../Assets/Images/img-01.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faTasks, faThumbsUp } from '@fortawesome/free-solid-svg-icons';

const Advantage = () => {
    return (
        <div>
            <section className="main-header mt-24 mb-16">
                <div className="w-10/12 m-auto  flex flex-col lg:flex-row">
                    <div className="lg:w-1/2 flex items-center px-10">
                        <div className="custom-container">
                            <div className="flex flex-col justify-center">
                                <div className="flex md:mb-5 items-center">
                                    <span className="border-l-2 border-[#b02027] h-6 mr-3"></span>
                                    <h1 className="md:text-lg text-sm font-bold">Contribute With Us</h1>
                                </div>
                                <div className="md:text-5xl text-xl font-semibold mb-8">
                                    <h3 className=" text-xl md:text-5xl py-3">
                                        Why Partner With Us?
                                    </h3>

                                </div>
                                <p>
                                    Our extensive reach ensures your scholarships get maximum visibility amongst deserving students across India.
                                </p>
                                <div className="my-5 py-2 grid grid-cols-1 gap-5">
                                    <div>
                                        <div className="header-card flex flex-wrap md:flex-nowrap items-center gap-4">
                                            <FontAwesomeIcon icon={faUsers} className="text-3xl bg-gray-100 rounded-full p-4 text-[#b02027] mb-4" />
                                            <div>
                                                <h4 className="text-2xl text-[#b02027]">Diverse Audience</h4>
                                                <p className="text-sm mt-3 text-slate-700">
                                                    We cater to students from all backgrounds and academic disciplines, allowing you to target specific scholarship recipients.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="header-card flex flex-wrap md:flex-nowrap items-center gap-4">
                                            <FontAwesomeIcon icon={faTasks} className="text-3xl bg-gray-100 rounded-full p-4 text-[#b02027] mb-4" />
                                            <div>
                                                <h4 className="text-2xl text-[#b02027] ">Streamlined Process</h4>
                                                <p className="text-sm mt-3 text-slate-700">Our user-friendly platform simplifies scholarship application and management for both students and donors.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="header-card flex flex-wrap md:flex-nowrap items-center gap-4">
                                            <FontAwesomeIcon icon={faThumbsUp} className="text-3xl bg-gray-100 rounded-full p-4 text-[#b02027] mb-4" />
                                            <div>
                                                <h4 className="text-2xl text-[#b02027]">Positive Brand Image</h4>
                                                <h6 className="text-sm mt-3 text-slate-700">
                                                    Partnering with us demonstrates your commitment to social responsibility and educational advancement, enhancing your brand image.
                                                    Benefits for Different Partners:
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="lg:w-1/2  grid-cols-2  px-4 sm:mt-0">
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 md:grid-cols-2  ">
              <div className="grid gap-4 ">
                <div className="">
                  <img
                  src="https://utfs.io/f/0b9f345c-998c-4810-a5cb-4095b7d4ae01-30jeur.jpg"
                    className="h-[200px] w-full rounded-lg object-cover object-center"
                    
                  />
                </div>
                <div className=" ">
                  <img
                   src="https://utfs.io/f/9f8f3c80-0d46-49bb-aca0-a629c7de7bfc-nwiuif.avif"
                    className="h-[510px] w-full rounded-lg object-cover object-center"
                  />
                </div>
              </div>
              <div className="grid gap-4">
                <div >
                  <img
                  src="https://utfs.io/f/103f3665-fd01-412d-975b-25f22f3ab2e0-l1iir9.avif"
                    className="h-[590px]  w-full rounded-lg object-cover object-center"
                    
                  />
                </div>
                <div>
                  <img
                  src="https://utfs.io/f/80c382bb-6c4b-4a77-ae07-8a15759d2053-x7gjq9.jpg"
                    className="h-[120px] w-full rounded-lg object-cover object-center"
                  />
                </div>
                
              </div>
            </div>
          </div>
                </div>
            </section>
        </div>
    );
};

export default Advantage;
