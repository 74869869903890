import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons'; // Import the file icon from Font Awesome
import masterData from '../../data'
const CreateScholarship = () => {
  const [formData, setFormData] = useState({
    title: '',
    type: '',
    organization: '',
    description: '',
    image: null,
    eligibility: '',
    amount: '',
  });

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: files ? files[0] : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('title', formData.title);
      formDataToSend.append('type', formData.type);
      formDataToSend.append('organization', formData.organization);
      formDataToSend.append('description', formData.description);
      formDataToSend.append('image', formData.image);
      formDataToSend.append('eligibility', formData.eligibility);
      formDataToSend.append('amount', formData.amount);

      const response = await fetch('https://getscholify-backend.onrender.com/api/scholarships', {
        method: 'POST',
        body: formDataToSend,
      });

      if (response.ok) {
        alert('Scholarship created successfully');
      } else {
        alert('Failed to create scholarship');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to create scholarship');
    }
  };

  return (
    <div className="mx-auto p-6 bg-white rounded-xl shadow-md shadow-gray-400">
      <h2 className="text-3xl font-semibold mb-8">Create a Scholarship</h2>
      <form className="space-y-6" onSubmit={handleSubmit}>
        <div>
          <label htmlFor="title" className="block text-gray-700 font-semibold mb-1">Title</label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleInputChange}
            className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-red-300"
            placeholder="Enter the title of the scholarship"
          />
        </div>
        <div>
          <label htmlFor="type" className="block text-gray-700 font-semibold mb-1">Type</label>
          <select
            id="type"
            name="type"
            value={formData.type}
            onChange={handleInputChange}
            className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-red-300"
          >
            <option value="">Select Type</option>
            {masterData.map((data, index) => (
              <option key={index} value={data.type}>{data.type}</option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="organization" className="block text-gray-700 font-semibold mb-1">Organization</label>
          <select
            id="organization"
            name="organization"
            value={formData.organization}
            onChange={handleInputChange}
            className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-red-300"
          >
            <option value="">Select Organization</option>
            {formData.type && masterData.find((data) => data.type === formData.type)?.organizations.map((organization, index) => (
              <option key={index} value={organization.name}>{organization.name}</option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="description" className="block text-gray-700 font-semibold mb-1">Description</label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            rows="4"
            className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-red-300"
            placeholder="Enter the description of the scholarship"
          ></textarea>
        </div>
        <div>
          <label htmlFor="image" className="block text-gray-700 font-semibold mb-1">Image</label>
          <div className="flex items-center justify-between border border-gray-300 rounded-md px-4 py-2 focus-within:border-blue-500">
            <input
              type="file"
              id="image"
              name="image"
              accept="image/*"
              onChange={handleInputChange}
              className="hidden"
            />
            <label
              htmlFor="image"
              className="cursor-pointer text-slate-600 hover:text-slate-700 focus:outline-none flex items-center"
            >
              <FontAwesomeIcon icon={faFile} className="mr-2" /> Choose File
            </label>
            <span className="text-gray-500">{formData.image ? formData.image.name : 'No file chosen'}</span>
          </div>
        </div>
        <div>
          <label htmlFor="eligibility" className="block text-gray-700 font-semibold mb-1">Eligibility</label>
          <textarea
            id="eligibility"
            name="eligibility"
            value={formData.eligibility}
            onChange={handleInputChange}
            rows="4"
            className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-red-300"
            placeholder="Enter the eligibility criteria for the scholarship"
          ></textarea>
        </div>
        <div>
          <label htmlFor="amount" className="block text-gray-700 font-semibold mb-1">Amount</label>
          <input
            type="number"
            id="amount"
            name="amount"
            value={formData.amount}
            onChange={handleInputChange}
            className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-red-300"
            placeholder="Enter the scholarship amount"
          />
        </div>
        <button
          type="submit"
          className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded-lg focus:outline-none focus:bg-red-600"
        >
          Create Scholarship
        </button>
      </form>
    </div>
  );
};

export default CreateScholarship;
