import React from 'react';
import '../../Styling/Prosper.css';

const Prosper = () => {
  return (
    <div className='bg-gray-100 '>
      <div className="w-10/12 py-10 md:py-24 mx-auto px-5 flex flex-wrap">
        <div className="flex flex-wrap mt-auto mb-auto lg:w-1/2 sm:w-full content-start sm:pr-10">
          <div className="flex flex-col align-middle justify-center">
            <div className="flex md:mb-5 items-center">
              <span className="border-l-2 border-[#b02027] h-6 mr-3"></span>
              <h1 className="md:text-lg text-sm font-bold">GETSCHOLIFY</h1>
            </div>
            <div className="md:text-5xl text-2xl font-semibold mb-8  "><h1 classNameName='text-black text-4xl md:text-7xl d:py-3 '>  <span>Prosper with Get</span><span className='text-[#AF0305]'>scholify</span></h1></div>
            <p>
              We believe that learning should not stop at any cost. With GetScholify, you can opt for the higher education of your choice. Be it college of your preference or any course/program of your choice.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-y-5 mt-10 mb-auto w-full content-start sm:pr-10">
              <div className="px-4 py-auto">
                <h2 className="title-font font-bold text-4xl py-5 text-gray-900">1500+</h2>
                <p className="leading-relaxed text-lg font-bold border-l-2 px-3 border-[#b02027]">Scholarships offered</p>
              </div>
              <div className="px-4">
                <h2 className="title-font font-bold text-4xl py-5 text-gray-900">200+</h2>
                <p className="leading-relaxed text-lg font-bold border-l-2 px-3 border-[#b02027]">Courses</p>
              </div>
              <div className="px-4">
                <h2 className="title-font font-bold text-4xl py-5 text-gray-900">50+</h2>
                <p className="leading-relaxed text-lg font-bold border-l-2 px-3 border-[#b02027]">contributors</p>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:w-1/2 sm:w-full w-[100%] rounded-md overflow-hidden md:mt-6 mt-6 px-4 sm:mt-0" >
          <img src="https://utfs.io/f/03bab854-e774-4449-a2fd-ffd8861b9e01-jhd6p1.avif" alt="Success" className="object-cover object-center h-full rounded-md shadow-md" style={{borderRadius:"0% 10% 0% 10% "}}  />
        </div>
      </div>
    </div>
  )
}

export default Prosper;
