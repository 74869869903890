
import React from "react";
import {
    Timeline,
    TimelineItem,
    TimelineConnector,
    TimelineIcon,
    Typography,
    TimelineHeader,
  } from "@material-tailwind/react";
  import {
    BellIcon,
    ArchiveBoxIcon,
    CurrencyDollarIcon,
  } from "@heroicons/react/24/solid";

const Process = () => {
  return (
    <div className="mb-12 mt-4 w-10/12 mx-auto ">
      <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-8 md:gap-4 gap-3">
     
      <div className="grid grid-cols-1 gap-4 ">
          <div className="lg:w-[70vh] w-full flex items-center justify-end  my-5 lg:my-0 pb-5">
            <Timeline>
              <TimelineItem className="h-40">
                <TimelineConnector className="" />
                <TimelineHeader className="relative rounded-xl border border-blue-gray-50 bg-white py-3 pl-4 pr-8 shadow-lg shadow-blue-gray-900/5">
                  <TimelineIcon className="p-3" variant="ghost">
                    <BellIcon className="h-5 w-5" />
                  </TimelineIcon>
                  <div className="flex flex-col gap-1 ">
                    <Typography variant="h6" color="blue-gray" className="text-[#b02027]">
                    Step 1
                    </Typography>
                    <Typography
                      variant="small"
                      color="gray"
                      className="font-normal"
                    >
                      Elevate your scholarship search with getscholify's seamless mobile experience. Unlock boundless opportunities for educational funding right from your fingertips.
                    </Typography>
                  </div>
                </TimelineHeader>
              </TimelineItem>
              <TimelineItem className="h-28">
                <TimelineConnector className="" />
                <TimelineHeader className="relative rounded-xl border border-blue-gray-50 bg-white py-3 pl-4 pr-8 shadow-lg shadow-blue-gray-900/5">
                  <TimelineIcon className="p-3" variant="ghost" color="red">
                    <ArchiveBoxIcon className="h-5 w-5" />
                  </TimelineIcon>
                  <div className="flex flex-col gap-1">
                    <Typography variant="h6" color="blue-gray" className="text-[#b02027]" >
                      Step 2
                    </Typography>
                    <Typography
                      variant="small"
                      color="gray"
                      className="font-normal"
                    >
                      Unlock the opportunity for every student to access scholarships tailored to their unique needs and aspirations.
                    </Typography>
                  </div>
                </TimelineHeader>
              </TimelineItem>
              <TimelineItem className="h-28">
                <TimelineHeader className="relative rounded-xl border border-blue-gray-50 bg-white py-3 pl-4 pr-8 shadow-lg shadow-blue-gray-900/5">
                  <TimelineIcon className="p-3" variant="ghost" color="green">
                    <CurrencyDollarIcon className="h-5 w-5" />
                  </TimelineIcon>
                  <div className="flex flex-col gap-1">
                    <Typography variant="h6" color="blue-gray" className="text-[#b02027]">
                      Step 3
                    </Typography>
                    <Typography
                      variant="small"
                      color="gray"
                      className="font-normal"
                    >
                      Enjoy the possibility of receiving up to 100% scholarship coverage for your education, with no eligibility conditions attached.
                    </Typography>
                  </div>
                </TimelineHeader>
              </TimelineItem>
            </Timeline>
          </div>
        </div>
        <div className="flex flex-col justify-center ">
          <p className="text-5xl font-bold">
          Unleash Your <span className="text-[#b02027]">Academic</span> Potential
          </p>
          <p className="text-lg pl-2 border-l-4 border-[#b02027] mt-6">
          Experience the possibility of securing up to 100% scholarship coverage  <span className="text-[#AF0305]">for your education, </span>
            <span>cfree from any eligibility constraints.</span>
          </p>
          {/* <p className="text-justify pt-6">
            <span className="text-[#AF0305]">
              Getscholify is a bridge between students, educators, and
              corporates. It is a digital platform designed to help the students
              of India with scholarships. The students getting scholarships to
              pursue their dream careers.
            </span>{" "}
            <br />
            Getscholify allows corporates & individuals to contribute to the
            education of these deserving children, and in doing that we are
            keeping everything transparent with the contributors. Donors can
            track their donations and can also get information about the
            scholarship recipient including the institution where students are
            studying.
          </p> */}
        </div>
      </div>
    </div>
    
  );
};

export default Process;























// import React from 'react'
// // import test1 from '../../Assets/img/GeorgeLee3x.a886009b.avif'

// const Testimonial = () => {
//     return (
//         <>
//             <section class="text-gray-600 body-font bg-slate-50">
//                 <div class="container mx-auto flex  py-12 md:flex-row flex-col items-center   w-4/5 ">
//                     <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
//                         <img class="object-cover object-center rounded" alt="" src="https://static-cse.canva.com/blob/1168085/Premiumfeatures2x.a7743f04.avif" />
//                     </div>
//                     <div class="lg:flex-grow  md:w-1/2  flex   justify-center md:items-start md:text-left items-center text-center">
//                         <div className="w-2/3">
//                             <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="block w-5 h-5 text-gray-400 mb-4" viewBox="0 0 975.036 975.036">
//                                 <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
//                             </svg>
//                             <p class="leading-relaxed mb-6 font-medium md:text-2xl">
//                                 We at Getscholify knows the role of education in the development of our country. Approximately, 20% of population in India is of (15 to 24) years of age, but only 27% attends college. We know Getscholify that for the better growth of a country we should educate our youth first. That is why we have created a platform which offers scholarships to every student of India. Our philosophy while building this platform was to support the dreams of young Indians, so that they can explore their field of interest and build a limitless career. </p>
//                             <a class="inline-flex items-center">

//                                 <span class="flex-grow flex flex-col ">
//                                     <span class="title-font font-medium text-gray-900">Holden Caulfield</span>
//                                     <span class="text-gray-500 text-sm">UI DEVELOPER</span>
//                                 </span>
//                             </a>
//                         </div>
//                     </div>
//                 </div>
//             </section>

//         </>
//     )
// }


// export default Testimonial