import React from 'react'

const Secondcomp = () => {
    return (
       <div className="bg-[#b02027] text-white rounded-full p-3 px-8 w-fit mx-12 z-20 relative mb-[-30px] md:mx-auto">
        <p>Transforming Lives and Communities Through Our Scholarships</p>
       </div>
    )
}

export default Secondcomp