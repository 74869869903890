import React from 'react';
import img1 from "../../Assets/edu.jpg";

const Secondsection = () => {
  return (
    <section className="py-12 py-md-5 py-xl-8 bg-[#fafafa]">
      <div className="w-10/12 mx-auto py-4  ">
        <div className="flex flex-wrap md:flex-nowrap gap-12 gy-3 gy-md-4 gy-lg-0 h-full items-center">

          <div className="h-full w-full md:w-1/2 flex flex-col justify-center">
            <div className="h-full flex flex-wrap md:flex-nowrap flex-col justify-center">
              <div className="h-full flex flex-wrap md:flex-nowrap flex-col justify-center">
                <h2 className="text-2xl md:text-4xl font-semibold pb-4 mb-0">Education is the right path to reach to the Destination</h2>
                <p className="text-[#b02027] text-xl text-secondary mb-3 font-semibold pb-8">
                  We understand today’s dynamic world and the aye skills required to compete in it.
                </p>
                <p className="mb-5" style={{ textAlign: 'justify' }}>Today the world is not limited to traditional careers, but now many new career option have evolved along with new. Scholarships Courses Contributors education courses and degrees.

                  To be able to stay on top of today’s competitive world, a good education is a must. To get high quality education good funds are required which is very difficult for every student to have.

                  Worry not! Getscholify is here to support your education with 100% scholarships.</p>
               
              </div>
            </div>
          </div>

          <div className="w-full md:w-1/2">
            {/* Gallery */}
            <div className="flex flex-wrap md:flex-nowrap gap-4 h-full">
              <div className="w-full md:w-1/2 col-md-12 mb-4 mb-lg-0">
                <img src="https://images.unsplash.com/photo-1495995424756-6a5a3f9e7543?q=80&w=2076&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" className="w-100 shadow-1-strong rounded mb-4" alt="Boat on Calm Water" />
                <img src="https://images.unsplash.com/photo-1592085550638-e6bc180a731e?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" className="w-100 shadow-1-strong rounded mb-4" alt="Wintry Mountain Landscape" />
              </div>
              <div className="w-full md:w-1/2 mb-4 mb-lg-0">
                <img src="https://images.unsplash.com/photo-1505226317007-67f4f53d8624?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" className="w-100 shadow-1-strong rounded mb-4" alt="Mountains in the Clouds" />
                <img src="https://images.unsplash.com/photo-1524069290683-0457abfe42c3?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&quot;" className="w-100 shadow-1-strong rounded mb-4" alt="Boat on Calm Water" />
              </div>
            </div>
            {/* Gallery */}
          </div>

        </div>
      </div>
    </section>
  );
}

export default Secondsection;










// import React from 'react';

// const Secondsection = () => {
//     return (
//         <div className='pt-10 pb-10'>
//             <section className="py-3 my-3 text-center shadow-md rounded-3xl border-t border-[#af0305]" id="about" style={{ maxWidth: '80%', margin: 'auto' }}>
//                 <div className="w-4/5 m-auto">
//                     <div className="my-3 py-3 text-center">
//                         <h3 className="text-5xl lg:text-3xl font-bold">
//                             <span className="text-[#af0305]">Education</span> is a right path to reach to the <span className="text-[#af0305]">Destination</span>
//                         </h3>
//                     </div>
//                     <div className="py-3">
//                         <i className="fas fa-quote-left"></i>
//                         <div className="text-lg nep-intro px-5 py-2">
//                             <span className="text-[#af0305]"> We understand today’s dynamic world and the aye skills
//                                 required to compete in it. </span>
//                             <br />Today the world is not limited to traditional careers, but now many new career option have evolved along with new. Scholarships Courses Contributors education courses and degrees.
//                         </div>
//                         <i className="fas fa-quote-right"></i>
//                     </div>
//                     <p className="nep-para text-xl pb-5">
//                         To be able to stay on top of today’s competitive world, a good education is a must. To get high quality education good funds are required which is very difficult for every student to have.
//                         <span className="text-[#af0305]"><br />Worry not! Getscholify is here to support your education with 100% scholarships.</span>
//                     </p>
//                 </div>
//             </section>
//         </div>
//     );
// }

// export default Secondsection;
