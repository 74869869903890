import React, { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth'; // Import getAuth from firebase/auth
import { auth } from '../../firebase/firebase'; // Import auth from your firebase configuration

const UserList = () => {
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(true); // State to track loading state

  useEffect(() => {
    const fetchUserList = async () => {
      try {
        const allUsers = []; // Array to store all users

        const listAllUsers = async (nextPageToken) => {
          const res = await getAuth(auth).listUsers(1000, nextPageToken);
          allUsers.push(...res.users);
          if (res.pageToken) {
            await listAllUsers(res.pageToken);
          } else {
            setUserList(allUsers); // Set the user list state when all users are fetched
            setLoading(false); // Update loading state to false
          }
        };

        await listAllUsers(); // Start listing all users when the component mounts
      } catch (error) {
        console.error('Error listing users:', error);
        setLoading(false); // Update loading state to false in case of error
      }
    };

    fetchUserList(); // Fetch user list when the component mounts
  }, []); // Empty dependency array ensures the effect runs only once after the component mounts

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-4">User List</h1>
      {loading ? (
        <p>Loading user list...</p> // Show loading message while fetching user list
      ) : (
        <ul>
          {userList.map((user) => (
            <li key={user.uid} className="border border-gray-300 rounded-md p-4 mb-4">
              <p className="font-semibold">Email: {user.email}</p>
              <p className="text-gray-600">Display Name: {user.displayName}</p>
              {/* Display other user data as needed */}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default UserList;
