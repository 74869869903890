

import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import placement from "../../Assets/students_09.jpg"
import placement2 from "../../Assets/corporaate.jpg"
import { Typography } from '@material-tailwind/react';


const Unlock = () => {
    const sectionData = [
        {
            heading: "For Students: ",
            subheading: "Every student has a dream, a dream to become successful and to support his/her family. But these dreams are often challenged by the financial incapacity of the families. Getscholify is revolutionizing the Indian education system by granting 100% scholarships, now any student can get the scholarship.Any student can apply for a scholarship with Getscholify. Our expert counselors help you to find the right course and choose the right college/university in your preferred city and then grant you the scholarship. Students applying for a scholarship through Getscholify have the privilege to select the college and degree of their choice. We understand the dynamic career requirements of today’s world, and that's why we are giving away scholarships in every field.",
            description: "READ MORE",
            img: placement
        },
        {
            heading: "For Corporates:",
            subheading: " In India, more than 2 million students complete their higher education every year, but only 27% out of them go on to pursue higher education in College/University. The main reason for this major drop is the economic incapacity of families of the students. Getscholify is helping the students with fully funded scholarships so that they can realize their dream career with quality education. To help a large number of students, we need help in terms of monetary contribution. We invite Corporates/Colleges/Individuals to become a part of this initiative and help give the youth of India the right direction towards a career.",
            description: "READ MORE",
            img: placement2
        },
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500,
        autoplay: true,
    };

    return (
        <div className='w-10/12 mx-auto py-10 my-5'>
            <p className="text-4xl pb-3 mb-3 font-semibold">
                <span className="border-l-4 border-[#b02027] pl-4">GetScholify</span> For

                <span className='text-[#b02027]'> Everyone</span>
            </p>
            <p className='text-lg font-semibold'>
                Unlock your potential with our comprehensive scholarship assistance program tailored    for both students and corporate careers.
            </p>


            <div className='mt-5'>
                <Slider {...settings}>

                    {sectionData.map((section, index) => (
                        <div key={index} className=" w-10/12 mx-auto rounded-md shadow-md text-white">
                            <div className='rounded-md mx-auto md:h-[50vh] h-auto' style={{ backgroundImage: `url(${section.img})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center center" }}>
                                <div className='bg-[#00000077] w-full h-full flex flex-col justify-end p-6 py-12'>
                                    <h2 className='text-4xl mb-4 font-semibold text-[#b02027]'>{section.heading}</h2>
                                    <p className='text-md lg:text-md w-full mb-2'>{section.subheading}</p>
                                </div>

                            </div>
                        </div>
                    ))}

                </Slider>
            </div>
        </div>
    );
}

export default Unlock;
