import React from 'react';

const IndividualForm = () => {
    return (
        <div className="flex items-center justify-center p-12">
            <div className="mx-auto w-full max-w-[900px] bg-white shadow-lg rounded-3xl p-8">
                <h2 className="text-center text-[#ff0000] py-3 text-3xl font-semibold">Contribute as an Individual</h2>
                <form>
                    <div className="custom-form-element mb-5">
                        <label htmlFor="amount" className="mb-3 block text-base font-medium text-[#07074D]">
                            Amount
                        </label>
                        <input type="text" name="amount" id="amount" placeholder="Enter the Amount"
                            className="custom-input w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#ff0000] focus:shadow-md" />
                    </div>
                    <div className="custom-form-element mb-5">
                        <label htmlFor="full-name" className="mb-3 block text-base font-medium text-[#07074D]">
                            Full Name
                        </label>
                        <input type="text" name="full-name" id="full-name" placeholder="Full Name"
                            className="custom-input w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#ff0000] focus:shadow-md" />
                    </div>
                    <div className="custom-form-element mb-5">
                        <label htmlFor="phone" className="mb-3 block text-base font-medium text-[#07074D]">
                            Phone Number
                        </label>
                        <input type="text" name="phone" id="phone" placeholder="Enter your phone number"
                            className="custom-input w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#ff0000] focus:shadow-md" />
                    </div>
                    <div className="custom-form-element mb-5">
                        <label htmlFor="email" className="mb-3 block text-base font-medium text-[#07074D]">
                            Email Address
                        </label>
                        <input type="email" name="email" id="email" placeholder="Enter your email"
                            className="custom-input w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#ff0000] focus:shadow-md" />
                    </div>
                    <div className="custom-form-element mb-5 pt-3">
                        <label className="mb-5 block text-base font-semibold text-[#07074D] sm:text-xl">
                            Address Details
                        </label>
                        <div className="-mx-3 flex flex-wrap">
                            <div className="w-full px-3 sm:w-1/2">
                                <div className="mb-5">
                                    <input type="text" name="area" id="area" placeholder="Enter area"
                                        className="custom-input w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#ff0000] focus:shadow-md" />
                                </div>
                            </div>
                            <div className="w-full px-3 sm:w-1/2">
                                <div className="mb-5">
                                    <input type="text" name="city" id="city" placeholder="Enter city"
                                        className="custom-input w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#ff0000] focus:shadow-md" />
                                </div>
                            </div>
                            <div className="w-full px-3 sm:w-1/2">
                                <div className="mb-5">
                                    <input type="text" name="state" id="state" placeholder="Enter state"
                                        className="custom-input w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#ff0000] focus:shadow-md" />
                                </div>
                            </div>
                            <div className="w-full px-3 sm:w-1/2">
                                <div className="mb-5">
                                    <input type="text" name="post-code" id="post-code" placeholder="Post Code"
                                        className="custom-input w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#ff0000] focus:shadow-md" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="custom-form-element mb-5">
                        <label htmlFor="pan-number" className="mb-3 block text-base font-medium text-[#07074D]">
                            PAN Number
                        </label>
                        <input type="text" name="pan-number" id="pan-number" placeholder="Enter PAN Number"
                            className="custom-input w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#ff0000] focus:shadow-md" />
                    </div>
                    <div className="custom-form-element">
                        <button
                            className="custom-button w-full rounded-md bg-[#FF0000] py-3 px-8 text-center text-base font-semibold text-white outline-none">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default IndividualForm;
