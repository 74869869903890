import React from 'react'
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import logoWhite from '../Assets/Brand/LogoWhite.png'
import logoBlack from '../Assets/Brand/logo.png'
const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <nav className="bg-white p-2 border-b ">
        <div className="w-11/12 text-xs md:text-md mx-auto flex justify-between items-center">
          <div>
            <Link to="/" className="text-black text-lg font-semibold">
              <img src={logoBlack} alt="TechLearns Logo" className="nav-logo" />
            </Link>
          </div>

          <div className="hidden md:flex space-x-4 items-center">
            <NavLink to="/about-us" className="text-black menu-link" activeClassName="font-bold">
              About Us
            </NavLink>
            <NavLink to="/why-us" className="text-black menu-link" activeClassName="font-bold">
              Why Us
            </NavLink>
            <NavLink to="/contribute-with-us" className="text-black menu-link" activeClassName="font-bold">
              Contribute With Us
            </NavLink>
            <NavLink to="/join-our-team" className="text-black menu-link" activeClassName="font-bold">
              Join Our Team
            </NavLink>
            <NavLink to="/scholarship" className="text-black menu-link" activeClassName="font-bold">
              Scholarship
            </NavLink>
            <NavLink to="/student">
              <button className="bg-red-800 text-white p-2 rounded-sm">
                Log In / Register
              </button>
            </NavLink>
          </div>

          <div className="md:hidden">
            <button onClick={toggleMenu} className="text-black focus:outline-none duration-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7"></path>
              </svg>
            </button>
          </div>
        </div>
      </nav>

      {isMenuOpen && (
        <div className="md:hidden fixed inset-0 bg-gray-800 bg-opacity-100 z-50 duration-500">
          <div className="flex justify-between p-4">
            <img src={logoBlack} alt="" srcset="" className='nav-logo' />
            <button onClick={toggleMenu} className="text-white duration-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
          </div>
          <div className="flex p-4 h-fit">
            <div className="flex flex-col space-y-4 text-white">
              <NavLink
                to="/"
                onClick={toggleMenu}
                className="text-xl font-bold"
                activeClassName="underline"
              >
                Home
              </NavLink>
              <NavLink
                to="/about-us"
                onClick={toggleMenu}
                activeClassName="underline"
              >
                About Us
              </NavLink>
              <NavLink
                to="/why-us"
                onClick={toggleMenu}
                activeClassName="underline"
              >
                Why Us
              </NavLink>
              <NavLink
                to="/contribute-with-us"
                onClick={toggleMenu}
                activeClassName="underline"
              >
                Contribute With Us
              </NavLink>
              <NavLink
                to="/join-our-team"
                onClick={toggleMenu}
                activeClassName="underline"
              >
                Join Our Team
              </NavLink>
              <NavLink
                to="/scholarship"
                onClick={toggleMenu}
                activeClassName="underline"
              >
                Scholarship
              </NavLink>
              <NavLink
                to="/student"
                onClick={toggleMenu}
                activeClassName="underline"
              >
                Login / Register
              </NavLink>
            </div>
          </div>
        </div>
      )}

    </>
  );
}

export default Navbar