import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import scholarships from '../scholarshipData';

const Scholarship = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const urlType = queryParams.get('type');
    const urlOrganization = queryParams.get('organization');

    const [filters, setFilters] = useState({
        type: urlType || '',
        organization: urlOrganization || '',
        amount: ''
    });
    const [scholarshipTypes, setScholarshipTypes] = useState([]);
    const [scholarshipOrganizations, setScholarshipOrganizations] = useState([]);

    useEffect(() => {
        // Extract all unique scholarship types and organizations
        const types = new Set();
        const organizations = new Set();

        scholarships.forEach(category => {
            category.scholarships.forEach(scholarship => {
                types.add(scholarship.type);
                organizations.add(scholarship.organisation);
            });
        });

        setScholarshipTypes(Array.from(types));
        setScholarshipOrganizations(Array.from(organizations));
    }, []);

    const filteredScholarships = () => {
        let filtered = [];
        scholarships.forEach(category => {
            filtered = filtered.concat(category.scholarships);
        });
        if (filters.type) {
            filtered = filtered.filter(scholarship => scholarship.type === filters.type);
        }
        if (filters.organization) {
            filtered = filtered.filter(scholarship => scholarship.organisation === filters.organization);
        }
        if (filters.amount) {
            filtered = filtered.filter(scholarship => parseFloat(scholarship.amount.split(' ')[1]) <= parseFloat(filters.amount));
        }
        return filtered;
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    };

    return (
        <section>
            <div className="w-10/12 mx-auto py-12 flex flex-wrap-reverse md:flex-nowrap gap-5">
                <div className='w-full md:w-3/4'>
                    <h1 className="text-3xl font-semibold mb-4 border-l-4 border-[#b02027] px-4">Scholarships</h1>
                    <div className="grid grid-cols-1">
                        {filteredScholarships().map((scholarship, index) => (
                            <div key={index} className="bg-white rounded-lg shadow-md p-4 my-4 flex flex-col md:flex-row">
                                <div className='w-full md:w-1/4 flex justify-center items-center'>
                                    {scholarship.image && (
                                        <img
                                            src={scholarship.image}
                                            alt={scholarship.title}
                                            className=" h-[10vh] object-contain"
                                        />
                                    )}
                                </div>
                                <div className='p-1 md:p-3 w-full md:w-3/4 flex flex-col justify-between'>
                                    <div className='flex flex-col justify-center h-full'>
                                        <h2 className="text-xl font-semibold mb-4">{scholarship.title}</h2>
                                        <p className="text-gray-600 mb-4">Offered by: {scholarship.organisation}</p>
                                        <p className="text-gray-600 mb-4">{scholarship.description}</p>
                                        <p className="text-sm font-semibold text-red-800 mb-4">Eligibility: {scholarship.eligibility}</p>
                                    </div>
                                    <div className='pt-4 flex justify-between items-center'>
                                        <p className="text-lg font-semibold text-red-800 w-full">Amount: {scholarship.amount}</p>
                                        <Link to='/student' className="w-full">
                                            <button className='bg-red-800 text-white rounded-md p-2 mt-3 md:mt-0 w-full'>Apply Now</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='w-full md:w-1/4 p-6 rounded-md shadow-md h-fit md:sticky top-10'>
                    <h2 className="text-lg font-semibold mb-2 text-[#b02027]">Other Filters</h2>
                    <select
                        className="mt-1 block w-full py-2 px-3 my-4 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        name="type"
                        value={filters.type}
                        onChange={handleFilterChange}
                    >
                        <option value="">Select Scholarship Type</option>
                        {scholarshipTypes.map((type, index) => (
                            <option key={index} value={type}>{type}</option>
                        ))}
                    </select>
                    <select
                        className="mt-1 block w-full py-2 px-3 my-4 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        name="organization"
                        value={filters.organization}
                        onChange={handleFilterChange}
                    >
                        <option value="">Select Scholarship Organization</option>
                        {scholarshipOrganizations.map((organization, index) => (
                            <option key={index} value={organization}>{organization}</option>
                        ))}
                    </select>
                    <input
                        type="number"
                        name="amount"
                        className="mt-1 block w-full py-2 px-3 my-4 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="Enter Maximum Amount"
                        value={filters.amount}
                        onChange={handleFilterChange}
                    />
                </div>
            </div>
        </section>
    );
};

export default Scholarship;
