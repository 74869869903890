import React from 'react'
import Contriform from './Contriform'

const ContributeHeader = () => {
    return (
        <div className="w-10/12 mx-auto py-12 h-full main-header flex flex-col justify-center">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 h-full">
                <div className="px-4 py-4 h-full">
                    <div className="h-full mx-auto flex flex-col justify-center header-written h-custom-height">
                        <p className="text-2xl lg:text-3xl font-bold text-gray-800 w-full pb-4">
                           Contribute With <span className="text-red-500">Us</span> <br />
                            On India's Best Scholarship Portal
                        </p>
                        <p className="text-gray-600 pb-4">
                        Contribute towards empowering the nation with quality education.
                        </p>
                        <div className="py-2 pb-4">



                        </div>
                    </div>
                </div>
                <div className="flex justify-center">
                    <Contriform />
                </div>
            </div>
        </div>
    )
}

export default ContributeHeader