import React, { useState, useEffect } from 'react';
import axios from 'axios';
import LetterUpload from './LetterUpload';

const ScholarshipRequests = () => {
  const [selectedScholarship, setSelectedScholarship] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [studentScholarships, setStudentScholarships] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    const fetchStudentScholarships = async () => {
      try {
        const response = await axios.get('https://getscholify-backend.onrender.com/api/student-scholarships');
        setStudentScholarships(response.data);
      } catch (error) {
        console.error('Error fetching student scholarships:', error);
      }
    };

    fetchStudentScholarships();
  }, []);

  const openModal = (scholarship) => {
    setSelectedScholarship(scholarship);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedScholarship(null);
    setIsModalOpen(false);
  };

  const increaseStatus = async () => {
    if (!selectedScholarship) {
      console.error('Selected scholarship is null');
      return;
    }

    try {
      const updatedStatus = selectedScholarship.status + 25;
      await axios.put(`https://getscholify-backend.onrender.com/api/student-scholarships/${selectedScholarship._id}/status`, { status: updatedStatus });
      const updatedScholarships = studentScholarships.map(scholarship => {
        if (scholarship._id === selectedScholarship._id) {
          return { ...scholarship, status: updatedStatus };
        }
        return scholarship;
      });
      alert('Value Changed Successfully');
      setStudentScholarships(updatedScholarships);
    } catch (error) {
      console.error('Error updating scholarship status:', error);
    }
  };

  const setApproved = async () => {
    if (!selectedScholarship) {
      console.error('Selected scholarship is null');
      return;
    }

    try {
      await axios.put(`https://getscholify-backend.onrender.com/api/student-scholarships/${selectedScholarship._id}/approve`, { approved: true });
      const updatedScholarships = studentScholarships.map(scholarship => {
        if (scholarship._id === selectedScholarship._id) {
          return { ...scholarship, approved: true };
        }
        return scholarship;
      });
      alert('Value Changed Successfully');
      setStudentScholarships(updatedScholarships);
    } catch (error) {
      console.error('Error setting scholarship approved status:', error);
    }
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const uploadFile = async () => {
    if (!selectedScholarship || !selectedFile) {
      console.error('Selected scholarship or file is null');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('userId', selectedScholarship.userId);
      formData.append('file', selectedFile);

      const response = await axios.post(`https://getscholify-backend.onrender.com/api/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      console.log('File uploaded successfully:', response.data);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  return (
    <div className="p-6">
      <h1 className="text-3xl font-bold mb-8">Scholarship Requests</h1>
      {studentScholarships.map((scholarship) => (
        <div key={scholarship._id} className="bg-white shadow-md rounded-lg p-6 mb-6">
          <div className="flex justify-between items-center">
            <div>
              <h2 className="text-lg font-semibold mb-2">{`${scholarship.firstName} ${scholarship.lastName}`}</h2>
              <p className="text-gray-600 mb-1">Status: {scholarship.status}</p>
              <p className="text-gray-600">Approved: {scholarship.approved ? 'Yes' : 'No'}</p>
            </div>
            <div>
              <button
                onClick={() => openModal(scholarship)}
                className="bg-red-500 text-white px-4 py-2 rounded-lg mr-2 hover:bg-red-600 focus:outline-none"
              >
                View
              </button>
            </div>
          </div>
        </div>
      ))}

      {isModalOpen && selectedScholarship && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-400 bg-opacity-50">
          <div className="bg-white p-8 rounded-lg">
            <h2 className="text-2xl font-semibold mb-6">Scholarship Details</h2>
            <p className="text-lg mb-2">{`${selectedScholarship.firstName} ${selectedScholarship.lastName}`}</p>
            <p className="text-gray-600 mb-2">Status: {selectedScholarship.status}</p>
            <p className="text-gray-600 mb-4">Approved: {selectedScholarship.approved ? 'Yes' : 'No'}</p>
            <div className="flex mb-4">
              <button onClick={increaseStatus} className="bg-red-500 text-white px-4 py-2 rounded mr-2 hover:bg-red-600 focus:outline-none">
                Increase Status by 25
              </button>
              <button onClick={setApproved} className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 focus:outline-none">
                Set Approved
              </button>
            </div>
            <LetterUpload id={selectedScholarship.userId} />
            <button onClick={closeModal} className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 focus:outline-none">
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ScholarshipRequests;
