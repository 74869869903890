import React from 'react'
import team from '../assets/Joinourteam/team.png'
const JoinHeader = () => {
    return (
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12 ">
                    <div>
                        <img src={team} width="100%" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default JoinHeader